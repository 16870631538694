import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';
import { UtilityService } from '../services/utility.service';
import { Group } from '../shared/models/group';
import { Patient } from '../shared/models/patient';
import { DateTime } from 'luxon';
import { Doctor } from '../shared/models/doctor';
import { Rx } from '../shared/models/rx';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  faxes: any = null;
  subscription: any;
  public loading: boolean = false;

  constructor(
    private db: AngularFirestore,
    private utility: UtilityService
  ) { }

  getFaxes() {
    this.loading = true;
    return this.db.collection(`faxes`, (ref) =>
    ref
      .orderBy('createdAt', 'desc')
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getRx(clinicId: string) {
    this.loading = true;
    return this.db.collection<Rx>(`prescriptions`, (ref) =>
    ref
      .where('clinic_id', '==', clinicId)
      .where('status', '!=', 'Batch')
    )
    .valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getDoctor(clinicId: string, doctorId: string) {
    return this.db.doc<Doctor>(`clinics/${clinicId}/doctors/${doctorId}`).valueChanges({idField: 'id'})
  }

}
