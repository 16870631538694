import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { first, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Group } from 'src/app/shared/models/group';
import { User } from 'src/app/shared/models/user';
import { PatientService } from '../patient.service';
import { FormGroup, FormControl, FormGroupDirective, NgForm, Validators, ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { DataService } from 'src/app/services/data.service';
import { Province } from 'src/app/shared/models/province';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UtilityService } from 'src/app/services/utility.service';
import { Diagnosis } from 'src/app/shared/models/diagnosis';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Medication } from 'src/app/shared/models/medication';
import { Doctor } from 'src/app/shared/models/doctor';
import { OrderService } from 'src/app/order/order.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-patient-add',
  templateUrl: './patient-add.component.html',
  styleUrls: ['./patient-add.component.scss']
})
export class PatientAddComponent implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject();
  pageTitle: string = null;
  isEdit: boolean = false;
  user: User;
  language: string;
  doctors: any;
  groups: Group[] = [];
  medications: any[] = [];
  medicationsFiltered: any[] = [];
  eyes: any[] = [];
  appSettings: any;
  provinces: Province[] = [];
  diagnosis: Diagnosis[] = [];
  privateRequired: boolean = false;
  
  patientId: string = null;
  patientForm: FormGroup;
  firstNameControl = new FormControl(null, [
    Validators.required,
  ]);
  lastNameControl = new FormControl(null, [
    Validators.required,
  ]);
  groupControl = new FormControl();
  dobControl = new FormControl(null);
  healthCardControl = new FormControl(null);
  languageControl = new FormControl('fr');
  genderControl = new FormControl(null, [
    Validators.required,
  ]);
  emailControl = new FormControl(null, [
    Validators.email,
  ]);
  phoneNumberControl = new FormControl();
  addressControl = new FormControl('');
  address2Control = new FormControl();
  cityControl = new FormControl('');
  provinceControl = new FormControl('', [
    Validators.required,
  ]);
  postalCodeControl = new FormControl('');
  doctorControl = new FormControl(null, [
    Validators.required,
  ]);
  diagnosisControl = new FormControl('');
  medicationControl = new FormControl('', [
    Validators.required,
  ]);
  eyeControl = new FormControl('', [
    Validators.required,
  ]);
  notesControl = new FormControl('');
  insuranceProviderControl = new FormControl('');
  chartIdControl = new FormControl('');
  insurancePolicyControl = new FormControl('');
  insuranceCertificateControl = new FormControl('');
  insuranceCarrierControl = new FormControl('');
  insuranceCriteriaControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
    private db: DataService,
    private patientService: PatientService,
    private utility: UtilityService,
    private translate: TranslateService,
    private orderService: OrderService,
    public dialogRef: MatDialogRef<PatientAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.language = this.translate.currentLang;
    this.patientForm = new FormGroup({
      first_name: this.firstNameControl,
      last_name: this.lastNameControl,
      group: this.groupControl,
      dateofbirth: this.dobControl,
      gender: this.genderControl,
      language: this.languageControl,
      email: this.emailControl,
      phone_number: this.phoneNumberControl,
      address: this.addressControl,
      address2: this.address2Control,
      city: this.cityControl,
      province: this.provinceControl,
      postal_code: this.postalCodeControl,
      notes: this.notesControl,
      doctor: this.doctorControl,
      diagnosis: this.diagnosisControl,
      medication: this.medicationControl,
      eye: this.eyeControl,
      healthcard: this.healthCardControl,
      chart_id: this.chartIdControl,
      insurance_provider: this.insuranceProviderControl,
      insurance_policy: this.insurancePolicyControl,
      insurance_certificate: this.insuranceCertificateControl,
      insurance_carrier: this.insuranceCarrierControl,
      insurance_criteria: this.insuranceCriteriaControl
    });
  }

  ngOnDestroy() {
    console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.db.getAppSettings().subscribe(async settings => {
      this.appSettings = settings;
      this.provinces = settings['activeProvinces'];
      this.diagnosis = settings['diagnosis'];
      settings['medications'].forEach(medication => {
        if (medication.provinces.includes(this.user.clinic_province)) {
          this.medications.push(medication);
        }
      });
      this.eyes = settings['eyes'];
      this.doctors = await this.orderService.getDoctorsByClinic(this.user.clinic_id).pipe(first()).toPromise();
      if (this.doctors.length === 1) {
        this.doctorControl.setValue(this.doctors[0]);
      }
    })
    this.patientService.getClinicGroups(this.user.clinic_id).valueChanges({idField: 'id'}).pipe(take(1)).subscribe(groups => {
      let defaultGroup: Group = <Group>{};
      defaultGroup.description = null;
      defaultGroup.group_name = this.translate.currentLang === 'fr' ? "Aucun groupe" : "No groups";
      defaultGroup.id = null;
      this.groups.push(defaultGroup);
      if (groups.length > 0)  {
        this.groups = this.groups.concat(groups);
      }
      this.groupControl.setValue(defaultGroup);
    })

    if (this.data) {
      const existingPatient = this.data;
      this.isEdit = true;
      this.patientId = existingPatient.id;
      console.log(this.patientId);
      this.patientForm.patchValue(existingPatient);
      console.log(`Date of birth - ${existingPatient.dob}`);
      const dobNew: any = DateTime.fromISO(`${existingPatient.dob}`).toJSDate() //.plus({day: 1}).toJSDate();
      console.log(`Parse dob - ${dobNew}`);
      this.dobControl.setValue(dobNew);
      this.pageTitle = existingPatient.full_name;
      if(existingPatient.insurance_criteria.includes('private')) this.privateRequired = true;
      // if (existingPatient.insurance_provider === 'RAMQ') this.insuranceGroupControl.disable();
    } else {
      this.provinceControl.setValue('QC');
    }
    this.onPatientFormChanges();
  }

  async addPatient(addRx?: boolean)  {
    console.log(this.patientForm.value);
    let dateofbirth = this.dobControl.value;
    console.log("DOB from control: ", dateofbirth)
    let dob = DateTime.fromJSDate(dateofbirth).toFormat('yyyy-MM-dd');
    console.log("DOB on save: ", dob)
    const patient = this.patientForm.value;
    patient.dob = dob;
    patient.id = this.patientId;
    patient.full_name = patient.first_name + ' ' + patient.last_name;
    delete patient.dateofbirth;
    if (patient.group.group_name === 'No groups' || patient.group.group_name === 'Aucun groupe') {
      patient.group = '';
    }

    if (this.isEdit)  {
      await this.patientService.editPatient(this.user.clinic_id, patient).then(() => {
        this.utility.showSnackBar(this.translate.instant('patients.success.edit'), 2000, 'center', 'top');
        if (addRx) this.dialogRef.close({ patientId: patient.id, addRx: true });
        else this.close();
      })
      .catch((err) => {
        this.utility.showSnackBar(this.translate.instant("patients.error.add"), 2000, 'center', 'top');
      })
    } else {
      patient.id = this.afs.createId();
      patient.consentSigned = false;
      patient.consent = null;
      await this.patientService.addPatient(this.user.clinic_id, patient).then(() => {
        this.utility.showSnackBar(this.translate.instant("patients.success.add"), 2000, 'center', 'top');
        if (addRx) this.dialogRef.close({ patientId: patient.id, addRx: true });
        else this.close();
      })
      .catch((err) => {
        this.utility.showSnackBar(this.translate.instant("patients.error.add"), 2000, 'center', 'top');
      })
    }
  }

  testClose() {
    this.dialogRef.close({ patientId: 'asdfasd88sdf7s8dfsdf', addRx: true });
  }

  // checkProviderValue()  {
  //   const provider = this.insuranceProviderControl.value;
  //   if (provider === 'RAMQ') {
  //     console.log('Disabled insurance group');
  //     this.insuranceGroupControl.setValue(null);
  //     this.insuranceGroupControl.disable();
  //   } else {
  //     console.log('Enable insurance group');
  //     this.insuranceGroupControl.enable();
  //   }
  // }

  onPatientFormChanges(): void {
    this.insuranceCriteriaControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(() => {
      const criteria: any = this.insuranceCriteriaControl.value;
      const validator = criteria.includes('private') ? [Validators.required] : null;
      this.privateRequired = criteria.includes('private') ? true : false;

      this.insuranceProviderControl.setValidators(validator);
      this.insuranceProviderControl.updateValueAndValidity();

      this.insurancePolicyControl.setValidators(validator);
      this.insurancePolicyControl.updateValueAndValidity();

      if (!this.privateRequired)  {
        this.insuranceProviderControl.setValue('');
        this.insurancePolicyControl.setValue('');
        this.insuranceCertificateControl.setValue('');
        this.insuranceCarrierControl.setValue('');
      }
    });
  }

  public compareMedications(c1: Medication, c2: Medication): boolean {
    if (c1 && c2) {
      return c1.din == c2.din;
    }
    return false;
  }

  compareDoctors(c1: Doctor, c2: Doctor): boolean {
    if (c1 && c2) {
      return c1.license_number == c2.license_number;
    }
    return false;
  }

  close() {
    this.dialogRef.close();
  }

}


export const dobHealthcardValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const dob = control.get('dateofbirth');
  const hc = control.get('healthcard');
  return dob && hc && (dob.value || hc.value) ? null : { oneRequired: true };
};

