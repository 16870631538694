<div><mat-toolbar color="accent">
    <span style="cursor: pointer;" mat-button routerLink="/order-list">{{ 'orders.title' | translate }}</span>&nbsp;>&nbsp;<span>{{ orderNumber }}</span><span *ngIf="order">&nbsp;>&nbsp;{{ orderInjectionClinicDoctor }} - {{ orderInjectionClinicDate }}</span>
    <span class="fill-space"></span>
    <ng-container *ngIf="orderSummaryUrl">
      <a mat-flat-button [href]="orderSummaryUrl" target="_blank">View PDF Summary</a>
    </ng-container>
    <!-- <a mat-flat-button [href]="orderSummaryUrl" target="_blank"><mat-icon>print</mat-icon>Print</a> -->
    <ng-container>
    <button [disabled]="reconciled" mat-flat-button color="warn" (click)="CompleteAndUpdate()">Complete Reconciliation -> Send Update</button>
    </ng-container> 
  </mat-toolbar>
  <kendo-grid

  [kendoGridBinding]="order"
  kendoGridSelectBy="id"
  [pageSize]="15"
  [pageable]="{
    buttonCount: 10,
    info: true,
    pageSizes: [15, 50, 100, 1000],
    previousNext: true
  }"
  [sortable]="{
    allowUnsort: true,
    mode: 'single'
  }"
  [sort]="sort" 
  [filterable]="true"
  [groupable]="true"
  (groupChange)="groupChange($event)"
  [reorderable]="true"
  [resizable]="true"
  [columnMenu]="{ filter: true }"
  [loading]="orderService.loading"
  >
  <kendo-grid-messages noRecords="{{ 'global.norecords' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
</kendo-grid-messages>
<ng-template kendoGridToolbarTemplate>
  <button type="button" kendoGridExcelCommand icon="file-excel">
    Export to Excel
  </button>
</ng-template>
<kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="240"  class="no-padding">
  <ng-template kendoGridCellTemplate let-dataItem> <span
    class="whole-cell"
    [style.backgroundColor]="!dataItem.AppointmentComplete ? '#FFBA80' : '#B2F699'"
  >
  <mat-checkbox [disabled]="reconciled" (click)="!reconciled ? toggleNoShow(dataItem) : null"
  [color]="!dataItem.AppointmentComplete ? 'warn' : 'accent'" [checked]="dataItem.AppointmentComplete">
  {{'injectionclinics.injectioncompleted' | translate}}
  </mat-checkbox>
  </span>
  </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="140"  class="no-padding">
    <ng-template kendoGridCellTemplate let-dataItem>
    <button mat-button [disabled]="reconciled" color="accent"  color="accent" (click)="openUpdateDialog(dataItem)">{{ 'orders.update' | translate }}</button>
    </ng-template>
    </kendo-grid-column>
  <kendo-grid-column field="patient.full_name" title="{{ 'patients.title' | translate }}" [width]="200"></kendo-grid-column>
  <!-- <kendo-grid-column field="patient.first_name" title="First Name"></kendo-grid-column> -->
  <kendo-grid-column field="patient.dob"  title="{{ 'patients.dob' | translate }}" [width]="175">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.patient.dob }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="doctor.full_name" title="{{ 'doctors.title' | translate }}" [width]="200">
    <ng-template kendoGridGroupHeaderTemplate let-value="value" let-aggregates="aggregates" let-field="field">
        <strong>{{ value }}</strong>: {{aggregates[field].count}}
     </ng-template>
  </kendo-grid-column>
  <ng-container *ngIf="user">
    <kendo-grid-column *ngIf="user.clinic.requiresAppointmentTime" [filterable]="false" field="injectiondate" [format]="{ date: 'short' }" [sortable]="true"  title="{{ 'rx.time' | translate }}" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.injectiondate | date: 'hh:mm a' }}</span>
      </ng-template>
    </kendo-grid-column>
  </ng-container>
  <kendo-grid-column field="medication_name" title="{{ 'rx.medication' | translate }}"  [width]="150">
    <ng-template kendoGridGroupHeaderTemplate let-value="value" let-aggregates="aggregates" let-field="field">
        <strong>{{ value }}</strong><!-- : {{aggregates[field]?.count}} -->
     </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eye" title="{{ 'rx.eye' | translate }}" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span translate>{{ dataItem.eye  }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="medication_name_update" title="{{ 'rx.medicationactual' | translate }}"  [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
        <strong>{{ dataItem.medication_name_update }}</strong><!-- : {{aggregates[field]?.count}} -->
     </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="eye_update" title="{{ 'rx.eyeactual' | translate }}" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span><strong translate>{{ dataItem.eye_update  }}</strong></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column filter="numeric" field="quantity_numeric" title="{{ 'rx.qty' | translate }}"  [width]="150"></kendo-grid-column>
  <kendo-grid-column field="patient.diagnosis" title="{{ 'patients.diagnosis' | translate }}" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.patient.diagnosis }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="note" title="{{ 'rx.notes' | translate }}" [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.note }}</span>
    </ng-template>
  </kendo-grid-column>
  <!-- <kendo-grid-column field="appointmentDate" [filterable]="false" title="{{ 'rx.appointmentdate' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.appointmentDate">
            {{ this.utility.formatDate(dataItem.appointmentDate, 'yyyy-MM-DD') }}
        </ng-container>
    </ng-template>
</kendo-grid-column> -->
  <!-- <kendo-grid-column field="status" title="{{ 'orders.status' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span translate>{{ dataItem.status }}</span>
    </ng-template>
  </kendo-grid-column> -->
          <kendo-grid-column field="patient.consentSigned" filter="boolean" title="{{ 'patients.consent' | translate }}" [width]="154">
            <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container *ngIf="dataItem.patient.consentSigned">
                    <a mat-button *ngIf="dataItem.patient.consentPdf" mat-button [href]="dataItem.patient.consentPdf.url" target="_blank">{{dataItem.patient.consentDate.toDate() | date: 'yyyy-MM-dd'}}</a>
                    <span *ngIf="!dataItem.patient.consentPdf">{{dataItem.patient.consentDate.toDate() | date: 'yyyy-MM-dd'}}</span>
                </ng-container>
                <ng-container *ngIf="!dataItem.patient.consentSigned">
                    <button mat-button (click)="signConsent(dataItem.id, dataItem.patient);">{{ 'patients.consent' | translate }}</button>
                </ng-container>
            </ng-template>
        </kendo-grid-column>

    <!-- <kendo-grid-command-column title="" [width]="300">
      <ng-template kendoGridCellTemplate>
        <button kendoGridEditCommand>Edit</button>
        <button kendoGridSaveCommand [disabled]="formGroup?.invalid">Update </button>
        <button kendoGridCancelCommand>Cancel</button>
      </ng-template>
    </kendo-grid-command-column> -->
    <kendo-grid-excel
    [fileName]="'order_' + orderNumber + '.xlsx'"
    [fetchData]="allData"
  ></kendo-grid-excel>
  </kendo-grid>
  </div>  



<ng-template #updateRxDialog>
  <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 matDialogTitle>{{ 'orders.detail.update.title' | translate }}</h2>
  <mat-dialog-content *ngIf="selectedRx">
    <form [formGroup]="rxForm">
      <div>{{'orders.detail.update.originalmedication' | translate}}: {{ selectedRx.medication_name }}</div>
      <div>
        <mat-form-field>
          <mat-label>{{ 'rx.medication' | translate }}</mat-label>
          <mat-select [formControl]="medicationControl">
            <mat-option *ngFor="let medication of medications" [value]="medication">{{ medication.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>{{'orders.detail.update.originaleye' | translate}}: {{ language === 'fr' ? selectedRx.eye : selectedRx.eye }}</div>
      <div>
        <mat-form-field color="accent" class="fc100">
          <mat-label>{{ 'rx.eye' | translate }}</mat-label>
          <mat-select [formControl]="eyeControl">
            <mat-option *ngFor="let eye of eyes" [value]="eye['value']">{{ language === 'fr' ? eye['name_fr'] : eye['name'] }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </form> 
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="accent" [disabled]="!rxForm.valid" (click)="submitUpdate()">{{ 'global.save' | translate }}</button>
</mat-dialog-actions>
</ng-template>


<ng-template #patientConsentDialog>
  <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 matDialogTitle>{{ 'patients.consent' | translate }}<span *ngIf="selectedPatient"> - {{ selectedPatient.full_name }}</span></h2>
  <mat-dialog-content class="mat-typography">
          <div>
              <mat-checkbox class="example-margin" [(ngModel)]="termsone"><span class="french_consent_text">J'ai posé toutes les questions voulues au sujet des particularités de mon traitement.&nbsp;-&nbsp;</span><span class="english_consent_text">I asked all the questions about the specifics of my treatment.</span></mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="termstwo"><span class="french_consent_text">J'ai donné accès à mes informations personnelles et consens à ce que la pharmacie puisse communiquer avec mon médecin concernant ma santé lorsque nécessaire.&nbsp;-&nbsp;</span><span class="english_consent_text">I have given access to my personal information and consent that the pharmacy can communicate with my doctor regarding my health when necessary.</span></mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="termsthree"><span class="french_consent_text">Je consens à ce qu'elle puisse communiquer avec mon assureur au sujet d'une réclamation.&nbsp;-&nbsp;</span><span class="english_consent_text">I give consent to the pharmacy to communicate with my insurer regarding a claim.</span></mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="termsfour"><span class="french_consent_text">On m'a offert la possibilité de faire affaire avec ma pharmacie habituelle.&nbsp;-&nbsp;</span><span class="english_consent_text">I was offered the possibility of doing business with my usual pharmacy.</span></mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="termsfive"><span class="french_consent_text">Je consens de manière libre et éclairée à ce que cette prescription soit exécutée par la Pharmacie Éric Beaulieu ayant une entente administrative avec la clinique.&nbsp;-&nbsp;</span><span class="english_consent_text">I freely and fully consent to this prescription being carried out by Pharmacie Éric Beaulieu having an administrative agreement with the clinic.</span></mat-checkbox>
          </div>

          <div style="border-bottom: 2px dashed black;">
              <signature-pad [id]="'d0'" [options]="{'minWidth': 2, 'canvasWidth': 700,'canvasHeight': 200 }"
                  (onBeginEvent)="drawStart(0)" (onEndEvent)="drawComplete(0)"></signature-pad>
          </div>
          <br/>
          </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button mat-button color="warn" [disabled]="!signature" (click)="clearSignature(0);">{{ 'rx.clearsignature' | translate }} </button>
      <button mat-button mat-dialog-close>{{ 'batch.cancel' | translate }}</button>
      <button mat-button color="accent" [disabled]="!termsone || !termstwo || !termsthree || !termsfour || !termsfive || !this.signature" (click)="submitConsent()">{{ 'rx.send' | translate
          }}</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #confirmSendUpdateDialog>
  <h2 matDialogTitle>Reconciliation Complete</h2>
  <mat-dialog-content>
    Please note that once sent, the order is locked and no more revisions are allowed. Are you sure you want to mark as complete and send this update?   
  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
      <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
  </mat-dialog-actions>
</ng-template>
  