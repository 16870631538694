<div>
    <mat-toolbar color="accent">
        <span>{{ 'users.title' | translate }}</span>
        <span class="fill-space"></span>
        <button mat-flat-button (click)="AddUser()">{{ 'users.adduser' | translate }}</button>
    </mat-toolbar>
    <kendo-grid
        [kendoGridBinding]="users | async"
        kendoGridSelectBy="id"
        [selectedKeys]="mySelection"
        [pageSize]="15"
        [pageable]="true"
        [sortable]="{
            allowUnsort: true,
            mode: 'single'
        }"
        [sort]="sort"
        [groupable]="false"
        [reorderable]="true"
        [resizable]="true"
        [columnMenu]="{ filter: true }"
        [loading]="userService.loading"
        (edit)="editHandler($event)" (cancel)="cancelHandler($event)"
        (save)="saveHandler($event)" (remove)="removeHandler($event)"
        (add)="addHandler($event)"
    >
    <kendo-grid-column field="last_name" title="{{ 'users.lastname' | translate }}" [groupable]="false"></kendo-grid-column>
    <kendo-grid-column field="first_name" title="{{ 'users.firstname' | translate }}" [groupable]="false"></kendo-grid-column>
    <kendo-grid-column field="email" title="{{ 'users.email' | translate }}" [groupable]="false"></kendo-grid-column>
    <kendo-grid-column [hidden]="user ? !(user.roles.retine_directe_admin || user.roles.retine_directe_reporting) : true" field="clinic_name" title="{{ 'users.clinic' | translate }}"></kendo-grid-column>
    <kendo-grid-column [hidden]="user ? !(user.roles.retine_directe_admin || user.roles.retine_directe_reporting) : true" field="roles" title="{{ 'users.role.role' | translate }}" [groupable]="false">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <!-- template for mobile -->
            <span *ngIf="dataItem.roles.retine_directe_admin" class="retine-directe_admin">{{ 'users.role.admin' | translate }}</span> 
            <span *ngIf="dataItem.roles.retine_directe_pharmacist" class="retine-directe_pharmacist">{{ 'users.role.pharmacist' | translate }}</span>
            <span *ngIf="dataItem.roles.retine_directe_reporting" class="retine-directe_reporting">{{ 'users.role.reporting' | translate }}</span> 
            <span *ngIf="dataItem.roles.clinic_admin" class="clinic_admin">{{ 'users.role.clinic' | translate }}</span> 
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <!-- <button style="width: 30px !important;" mat-button color="primary" (click)="editUser(dataItem);"><mat-icon>edit</mat-icon></button> -->
            <button mat-button color="warn" (click)="DeleteUser(dataItem)"><mat-icon>delete</mat-icon></button>
            <button mat-button color="accent" (click)="SendPasswordReset(dataItem.email)" title="{{ 'users.sendpasswordresetemail' | translate }}"><mat-icon>forward_to_inbox</mat-icon></button>
        </ng-template>
      </kendo-grid-column>
  </kendo-grid>
</div>

<ng-template #deleteConfirmDialog>
    <h2 matDialogTitle>{{ 'users.delete.title' | translate }}</h2>
    <mat-dialog-content>
        <div>
        {{ 'users.delete.message' | translate }} {{ deleteUserFullName }}
         </div>
         <div>
            <mat-form-field class="fc300">
                <mat-label>{{ 'users.name' | translate }}</mat-label>
                <input matInput [(ngModel)]="typedUserFullName" />
            </mat-form-field>   
         </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
        <button mat-flat-button color="warn" [disabled]="deleteUserFullName !== typedUserFullName" matDialogClose="yes">{{ 'global.delete' | translate }}</button>
    </mat-dialog-actions>
  </ng-template>

  <ng-template #addDialog>
    <h2 matDialogTitle>{{ 'users.adduser' | translate }}</h2>
    <mat-dialog-content>
        <ng-container *ngIf="user">
            <div *ngIf="userRolesControl.value === 'clinic_admin'">
                As a rétine directe administrator, you are currently working within the following clinic: {{ user.clinic_name }}.  <strong>Please be aware this user will be assigned to this clinic.</strong> 
            </div>
        </ng-container>
         <div style="margin-top: 15px;">
            <form [formGroup]="userForm">
                <div>
                    <mat-form-field class="fc300">
                      <mat-label>{{ 'global.email' | translate }}</mat-label>
                      <input matInput cdkFocusInitial [formControl]="userEmailControl" [errorStateMatcher]="matcher" required />
                      <!-- <mat-icon matSuffix>mail</mat-icon> -->
                      <mat-error *ngIf="userEmailControl.hasError('email') && !userEmailControl.hasError('required')" [innerHTML]="'global.invalidemail' | translate"></mat-error>
                      <mat-error *ngIf="userEmailControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                  </mat-form-field>
                  &nbsp;
                  <mat-form-field class="fc100" >
                      <mat-label>{{ 'global.language' | translate }}</mat-label>
                      <mat-select [formControl]="userLanguageControl" [errorStateMatcher]="matcher" required>
                        <mat-option value="fr">Français</mat-option>
                        <mat-option value="en">English</mat-option>
                      </mat-select>
                      <mat-error *ngIf="userLanguageControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="fc200">
                        <mat-label>{{ 'global.firstname' | translate }}</mat-label>
                        <input matInput cdkFocusInitial [formControl]="userFirstNameControl" [errorStateMatcher]="matcher" required/>
                        <mat-error *ngIf="userFirstNameControl.hasError('required')"
                          [innerHTML]="'global.required' | translate"></mat-error>
                      </mat-form-field>
                      &nbsp;                       
                      <mat-form-field class="fc200">
                        <mat-label>{{ 'global.lastname' | translate }}</mat-label>
                        <input matInput [formControl]="userLastNameControl" [errorStateMatcher]="matcher" required/>
                        <mat-error *ngIf="userLastNameControl.hasError('required')"
                          [innerHTML]="'global.required' | translate"></mat-error>
                      </mat-form-field>
                </div>
                <div>
                    <mat-radio-group class="role-radio-group" name="userRolesControl" [formControl]="userRolesControl" >
                       <mat-radio-button class="role-radio-button" [checked]="true" value="clinic_admin">Clinic User</mat-radio-button>
                       <mat-radio-button *ngIf="user.roles.retine_directe_admin || user.roles.retine_directe_reporting" class="role-radio-button" value="retine_directe_admin">rétine directe Admin</mat-radio-button>
                       <mat-radio-button *ngIf="user.roles.retine_directe_admin || user.roles.retine_directe_reporting" class="role-radio-button" value="retine_directe_pharmacist">rétine directe Pharmacist</mat-radio-button>
                       <mat-radio-button *ngIf="user.roles.retine_directe_admin || user.roles.retine_directe_reporting" class="role-radio-button" value="retine_directe_reporting">rétine directe Reporting</mat-radio-button>
                    </mat-radio-group>
                </div>
                </form>
                <div class="mat-caption" class="emailexists"><span *ngIf="emailExists">{{ 'global.emailexists' | translate }}</span>&nbsp;</div>
         </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.cancel' | translate }}</button>
        <button mat-flat-button color="accent" [disabled]="!userForm.valid || emailExists" matDialogClose="yes" (click)="submitUserRequest()">{{ 'global.add' | translate }}</button>
    </mat-dialog-actions>
  </ng-template>