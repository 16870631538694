import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UtilityService } from '../services/utility.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  @ViewChild('resetPasswordDialog') resetPasswordDialog: TemplateRef<any>;
  ngUnsubscribe: Subject<void> = new Subject();
  form: FormGroup;
  loading = false;
  serverMessage: string = '';
  emailAddress: string = '';
  env;
  version;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private utility: UtilityService
  ) {
    this.env = environment.MODE;
    this.version = environment.VERSION;
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.minLength(6), Validators.required]],
    });
  }

  ngOnInit() {
    this.emailAddress = '';
  }

  ngOnDestroy() {
    console.log('Destroy Login View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }

  get passwordConfirm() {
    return this.form.get('passwordConfirm');
  }

  async onSubmit() {
    this.loading = true;

    const email = this.form.get('email')?.value;
    const password = this.form.get('password')?.value;

    try {
      await this.auth.emailLogin(email, password);
      const user = await this.auth.getCurrentUser();
      console.log("Sign in: ", user.display_name, user.resetPassword);
      this.translate.use(user.language ? user.language : 'fr');
      await this.router.navigate(['/patient-list']);
    } catch (err) {
      this.serverMessage = err;
    }

    this.loading = false;
  }

  async chooseLanguage(language: string) {
    await this.translate.use(language);
  }

  async resetPassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '350px';
    let dialogRef = this.matDialog.open(this.resetPasswordDialog, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        if (result === 'yes') {
          this.auth.sendPasswordResetEmail(this.emailAddress).then(async () => {
            this.utility.showSnackBar(this.translate.instant('global.resetpasswordrequest.success'), 2000, 'center', 'top');
            await this.router.navigate(['/patient-list']);
          })
          .catch((error) => {
            this.utility.showSnackBar(this.translate.instant('global.error.resetpasswordrequest'), 2000, 'center', 'top');
          });
        }
      }
    });
  }
}
