<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isPhone$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isPhone$ | async) ? 'over' : 'side'"
      [opened]="false">
    <mat-toolbar>
      <img src="../../../assets/images/logo_transparent_grey.png" style="width: 100px; margin-right: 30px;">
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/patient-list" (click)="drawer.close()">{{ 'shell.patients' | translate }}</a>
      <!-- <a mat-list-item routerLink="/order-list" (click)="drawer.close()">
        <span [matBadge]="batchCount" matBadgeOverlap="false" matBadgeColor="warn">{{ 'shell.rxorders' | translate }}</span>
      </a> -->
      <a mat-list-item routerLink="/supply-order" (click)="drawer.close()">{{ 'shell.supplyorder' | translate }}</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>

    <mat-toolbar>
      <button 
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="(isPhone$ | async)">


        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span *ngIf="!((isPhone$ | async) || (isTablet$ | async))">
        <img src="../../../assets/images/logo_transparent_grey.png" style="width: 160px; margin-right: 30px;">
      </span>
      <!-- <ng-container *ngIf="!(afAuth.authState | async)"> -->
      <!-- <span class="logo" routerLink="/home">retine-directe Portal</span> -->
      <ng-container *ngIf="user">
      <div *ngIf="!(isPhone$ | async)">
       
          <!-- <a mat-button routerLink="/home">Home</a> -->
          <a mat-button routerLink="/patient-list">
            <!-- <mat-icon>assignment_ind</mat-icon> -->
            <span>{{ 'shell.patients' | translate }}</span>
          </a>
          <!-- <a mat-button *ngIf="batchCount > 0" [routerLink]="batchCount > 0 ? '/order-list' : '/patient-list'"> -->
            <a mat-button [matMenuTriggerFor]="injectionClinicMenu"><span>{{ 'shell.injectionclinics' | translate }}</span></a>
            <mat-menu #injectionClinicMenu="matMenu">
              <a mat-menu-item mat-menu-item (click)="openCreateInjectionClinic()">{{ 'shell.injectionclinic.createnew' | translate }}</a>
              <ng-container *ngIf="injectionClinics.length > 0">
                <button mat-menu-item [matMenuTriggerFor]="injectionMenu">{{ 'shell.chooseclinic' | translate }}</button>
                <mat-menu #injectionMenu="matMenu">
                  <a *ngFor="let clinic of injectionClinics" (click)="selectInjectionClinic(clinic)" mat-menu-item mat-menu-item>{{ clinic.name ? clinic.name : '' }} - {{ this.utility.formatDate(clinic.clinicDate, 'YYYY-MM-DD') }}</a>
                </mat-menu>
              </ng-container>
              <mat-divider></mat-divider>
              <a mat-menu-item mat-menu-item routerLink="injection-clinic/injection-clinic-list">Injection Clinic List</a> 
            </mat-menu>
          <a *ngIf="user.injectionclinic" mat-button routerLink="/rx-list"> 
            <!-- <mat-icon>medication</mat-icon>  -->
            <span style="display: inline-block;" [matBadge]="batchCount" matBadgeOverlap="false" matBadgePosition="above after" matBadgeColor="warn">{{ user.injectionclinic.name ? user.injectionclinic.name : '' }} {{ this.utility.formatDate(user.injectionclinic.clinicDate, 'YYYY-MM-DD') }}</span>
          </a>
          <!-- &nbsp;&nbsp; -->
          <a mat-button routerLink="/supply-order">
            <!-- <mat-icon>inventory_2</mat-icon> -->
            <span>{{ 'shell.supplyorder' | translate }}</span>
          </a>
          <a mat-button [matMenuTriggerFor]="ordersMenu">
            <!-- <mat-icon>description</mat-icon> -->
            <span>{{ 'shell.reports' | translate }}</span>
          </a>
          <mat-menu #ordersMenu="matMenu">
            <a mat-menu-item routerLink="/order-list">
              <span>{{ 'shell.orders' | translate }}</span>
            </a>
            <a mat-menu-item routerLink="/reports/rx-report">
              <span>{{ 'shell.prescriptions' | translate }}</span>
            </a>
          </mat-menu>

          <ng-container *ngIf="user.roles.clinic_admin">
            <a mat-button [matMenuTriggerFor]="sysAdminMenu">{{ 'shell.admin' | translate }}</a>
            <mat-menu #sysAdminMenu="matMenu">
              <a mat-menu-item mat-menu-item routerLink="admin/user-list">Users</a>
              <a mat-menu-item mat-menu-item routerLink="admin/doctor-list">Doctors</a>
            </mat-menu>
          </ng-container>

          <ng-container *ngIf="user.roles.retine_directe_admin">
            <a mat-button [matMenuTriggerFor]="sysAdminMenu"><mat-icon>admin_panel_settings</mat-icon>{{ 'shell.sysadmin' | translate }}</a>
            <mat-menu #sysAdminMenu="matMenu">
              <a mat-menu-item mat-menu-item routerLink="admin/clinic-list">Clinics</a>
              <a mat-menu-item mat-menu-item routerLink="admin/doctor-list">Doctors</a>
              <a mat-menu-item mat-menu-item routerLink="admin/user-list">Users</a>
              <a mat-menu-item mat-menu-item routerLink="admin/supply-list">Supplies</a>
              <a mat-menu-item mat-menu-item routerLink="/patient-upload">Upload Patients</a>
              <a mat-menu-item mat-menu-item routerLink="reports/fax-report">Fax Report</a>
              <mat-divider></mat-divider>
              <a mat-menu-item mat-menu-item routerLink="admin/utility">Utility</a>
              <a mat-menu-item mat-menu-item routerLink="reports/clinic-schedule">Clinic Bookings</a>
            </mat-menu>
          </ng-container>
      </div>

      <span class="fill-space"></span>
      <span></span>
      <a mat-button [matMenuTriggerFor]="userMenu">
        {{ user.first_name }} - {{ user.clinic_name }}
        <mat-icon>expand_more</mat-icon>
      </a>
      
      <mat-menu #userMenu="matMenu">
        <button *ngIf="!user.roles.clinic_admin" mat-menu-item [matMenuTriggerFor]="clinicMenu">{{ 'shell.clinicselect' | translate }}</button>
        <mat-menu #clinicMenu="matMenu">
          <a *ngFor="let clinic of clinics" (click)="selectClinic(clinic, clinic.clinic_id, clinic.clinic_name)" mat-menu-item mat-menu-item>{{ clinic.clinic_name }}</a>
        </mat-menu>
        <button mat-menu-item [matMenuTriggerFor]="languageMenu">{{ 'shell.chooselanguage' | translate }}</button>
        <mat-menu #languageMenu="matMenu">
          <a mat-menu-item mat-menu-item (click)="chooseLanguage('fr')">Français</a>
          <a mat-menu-item mat-menu-item (click)="chooseLanguage('en')">English</a>
        </mat-menu>
        <a mat-menu-item (click)="changePassword()">
          <span>{{ 'shell.changepassword' | translate }}</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-menu-item (click)="logout()">
          <span>{{ 'shell.signout' | translate }}</span>
        </a>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="showAbout()"> {{ 'global.version' | translate }} {{ version }}</button>
      </mat-menu>
    <!-- </ng-container> -->
  </ng-container>
    </mat-toolbar>

    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #newPasswordDialog>
  <button *ngIf="!mandatoryPasswordChange" mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 matDialogTitle>{{ 'global.newpassword.title' | translate }}</h2>
  <mat-dialog-content>
      <div>
      {{ 'global.newpassword.message' | translate }}
       </div>
       <div>
          <mat-form-field class="fc150">
              <mat-label>{{ 'login.password' | translate }}</mat-label>
              <input matInput cdkFocusInitial [(ngModel)]="newPassword" maxlength="14" minlength="10" />
          </mat-form-field>   
          <password-strength-meter [password]="newPassword"></password-strength-meter>
       </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
      <button mat-flat-button color="accent" [disabled]="newPassword.length < 10" matDialogClose="yes">{{ 'global.save' | translate }}</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #showAboutDialog>
  <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 matDialogTitle>{{ 'global.about' | translate }}</h2>
  <mat-dialog-content>
      <div>
        {{ 'global.version' | translate }} {{ version }}
       </div>
       <div>
        {{ 'global.releasedon' | translate }} {{ releaseDate }}
       </div>
       <div style="margin-top: 10px;">
         <strong>Notes de version / Release Notes</strong>
        <ul>
          <li>Corrections de bugs sur iPad/ iPad fixes</li>
          <li>Exporter les données complètes d'Excel / Export Excel full data</li>
        </ul>
      </div>
  </mat-dialog-content>
</ng-template>

<ng-template #injectionClinic>
  <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 matDialogTitle>{{ 'shell.injectionclinic.new' | translate }}</h2>
  <mat-dialog-content>
    <form [formGroup]="rxForm">
      <!-- <img class="logo" [src]="logo"> -->
      <!-- <div>
        <mat-form-field>
          <mat-label>{{ 'rx.doctor' | translate }}</mat-label>
          <mat-select cdkFocusInitial [formControl]="doctorControl">
            <mat-option *ngFor="let doctor of doctors" [value]="doctor">Dr {{ doctor.full_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <!-- [matDatepickerFilter]="dateFilter" -->
      <div>
        <mat-form-field class="fc250" appearance="outline">
          <mat-label>{{ 'shell.injectionclinic.name' | translate }}</mat-label>
          <input matInput [formControl]="nameControl" required />
         </mat-form-field>
      </div>
      <div>
        <mat-form-field class="fc250"  appearance="outline">
          <mat-label>{{ 'shell.injectionclinic.date' | translate}}</mat-label>
          <input matInput [formControl]="clinicDateControl" [matDatepicker]="cd"  placeholder="{{ 'shell.injectionclinic.date' | translate}}">
          <mat-datepicker-toggle matSuffix [for]="cd"></mat-datepicker-toggle>
          <mat-datepicker #cd disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="fc250"  appearance="outline">
          <mat-label>{{ 'rx.location' | translate }}</mat-label>
          <mat-select [formControl]="locationControl">
            <mat-option *ngFor="let location of locations" [value]="location">{{ location.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </form> 
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="accent" [disabled]="!rxForm.valid" (click)="submitInjectionClinic()">{{ 'global.save' | translate }}</button>
</mat-dialog-actions>
</ng-template>
