import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Patient } from 'src/app/shared/models/patient';
import { User } from 'src/app/shared/models/user';
import { ReportService } from '../report.service';

@Component({
  selector: 'fax-report',
  templateUrl: './fax-report.component.html',
  styleUrls: ['./fax-report.component.scss'],
})
export class FaxReportComponent implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject();
  faxes: any;
  public gridView: any;
  user: User = <User>{};
  appSettings: any;
  
  constructor(
    private auth: AuthService,
    public reportService: ReportService,
    private utility: UtilityService,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private db: DataService
  ) {}

  ngOnInit() {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.faxes = this.reportService.getFaxes().pipe(
      map((patients) => {
        // patients.forEach((patient) => {
        //   patient['dob'] = DateTime.fromFormat(patient['dob'], 'yyyy-MM-dd');
        // });
        return patients;
      }),
      takeUntil(this.ngUnsubscribe)
    );

    this.db.getAppSettings().subscribe(settings => {
      this.appSettings = settings;
    });
  }

  ngOnDestroy() {
    console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
