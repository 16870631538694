<div>
    <mat-toolbar color="accent">
        <span>{{ 'clinic.title' | translate }}</span>
        <span class="fill-space"></span>
        <button mat-flat-button (click)="AddClinic()">{{ 'clinic.setupnewclinic' | translate }}</button>
    </mat-toolbar>
    <kendo-grid
        [kendoGridBinding]="clinics | async"
        kendoGridSelectBy="id"
        [pageSize]="15"
        [pageable]="true"
        [sortable]="{
            allowUnsort: true,
            mode: 'single'
        }"
        [filterable]="true"
        [groupable]="false"
        [reorderable]="true"
        [resizable]="true"
        [columnMenu]="{ filter: true }"
        [loading]="adminService.loading"
    >
    <kendo-grid-messages filterBooleanAll="{{ 'patients.filter.all' | translate }}" filterIsTrue="{{ 'patients.filter.true' | translate }}" filterIsFalse="{{ 'patients.filter.false' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
    </kendo-grid-messages>
    <kendo-grid-column field="clinic_name" title="{{ 'clinic.clinicname' | translate }}" [width]="350"></kendo-grid-column>
    <kendo-grid-column field="address.city" title="{{ 'clinic.city' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="fax_number" title="{{ 'clinic.faxnumber' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="phone_number" title="{{ 'clinic.phonenumber' | translate }}"></kendo-grid-column>
    <kendo-grid-column field="createdAt" title="{{ 'clinic.createdat' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.createdAt">{{ utility.formatDate(dataItem.createdAt, 'yyyy-MM-DD hh:mm a') }}</span>
        </ng-template> 
    </kendo-grid-column>
    <kendo-grid-column field="createdBy" title="{{ 'clinic.createdby' | translate }}"></kendo-grid-column>
    <kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
            <!-- <button style="width: 30px !important;" mat-button color="primary" (click)="EditClinic(dataItem);"><mat-icon>edit</mat-icon></button> -->
            <button mat-button color="accent" (click)="updateClinicSupplies(dataItem)">{{ 'global.supplies' | translate }}</button>
            <button mat-button color="warn" (click)="DeleteClinic(dataItem.id, dataItem.clinic_name)"><mat-icon>delete</mat-icon></button>
        </ng-template>
      </kendo-grid-column>
  </kendo-grid>
  </div>    


<ng-template #deleteConfirmDialog>
    <h2 matDialogTitle>{{ 'clinic.deleteclinic' | translate }}</h2>
    <mat-dialog-content>
        <div>
        {{ 'clinic.deletemessage' | translate }} {{ deleteClinicName }}
         </div>
         <div>
            <mat-form-field class="fc300">
                <mat-label>{{ 'clinic.clinicname' | translate }}</mat-label>
                <input matInput [(ngModel)]="typedClinicName" />
            </mat-form-field>   
         </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
        <button mat-flat-button color="warn" matDialogClose="yes">{{ 'global.delete' | translate }}</button>
    </mat-dialog-actions>
  </ng-template>
  
  <ng-template #setupClinicDialog>
    <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 matDialogTitle class="title">{{ 'clinic.setupnewclinic' | translate }}</h2>
    <mat-dialog-content class="mat-typography">
      <mat-horizontal-stepper linear #stepper>
          <mat-step label="Clinic Information" completed="false">
              <div>
                <form [formGroup]="clinicForm">
                    <div>
                        <mat-form-field class="fc300">
                            <mat-label>{{ 'clinic.clinicname' | translate }}</mat-label>
                            <input matInput cdkFocusInitial [formControl]="clinicNameControl" [errorStateMatcher]="matcher" required/>
                            <mat-error *ngIf="clinicNameControl.hasError('required')"
                              [innerHTML]="'global.required' | translate"></mat-error>
                          </mat-form-field>
                          &nbsp;
                          <mat-form-field class="fc100" >
                              <mat-label>{{ 'global.language' | translate }}</mat-label>
                              <mat-select [formControl]="clinicLanguageControl" [errorStateMatcher]="matcher" required>
                                <mat-option value="fr">Français</mat-option>
                                <mat-option value="en">English</mat-option>
                              </mat-select>
                              <mat-error *ngIf="clinicLanguageControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                            </mat-form-field>
                    </div>
                    <div>
                          <mat-form-field class="fc200">
                            <mat-label>{{ 'global.email' | translate }}</mat-label>
                            <input matInput [formControl]="emailControl" [errorStateMatcher]="matcher" />
                            <!-- <mat-icon matSuffix>mail</mat-icon> -->
                            <mat-error *ngIf="emailControl.hasError('email') && !emailControl.hasError('required')" [innerHTML]="'global.invalidemail' | translate"></mat-error>
                            <mat-error *ngIf="emailControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                        &nbsp;
                        <mat-form-field class="fc150">
                            <mat-label>{{ 'global.phone' | translate }}</mat-label>
                            <input matInput [formControl]="phoneNumberControl" [errorStateMatcher]="matcher" required/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="phoneNumberControl.hasError('required')">
                                {{ 'global.required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="phoneNumberControl.hasError('pattern')">
                                {{ 'global.invalidphone' | translate }}
                            </mat-error>
                        </mat-form-field>
                        &nbsp;                        <mat-form-field class="fc150">
                            <mat-label>{{ 'global.fax' | translate }}</mat-label>
                            <input matInput [formControl]="faxNumberControl" [errorStateMatcher]="matcher" required/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="faxNumberControl.hasError('required')">
                                {{ 'global.required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="faxNumberControl.hasError('pattern')">
                                {{ 'global.invalidphone' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="fc200">
                            <mat-label>{{ 'global.address' | translate }}</mat-label>
                            <input matInput [formControl]="addressControl" [errorStateMatcher]="matcher" required />
                            <mat-error *ngIf="addressControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                        &nbsp;
                        <mat-form-field class="fc200">
                            <mat-label>{{ 'global.address2' | translate }}</mat-label>
                            <input matInput [formControl]="address2Control"/>
                        </mat-form-field>
                        &nbsp;            
                        <mat-form-field class="fc125">
                            <mat-label>{{ 'global.city' | translate }}</mat-label>
                            <input matInput [formControl]="cityControl" [errorStateMatcher]="matcher" required />
                            <mat-error *ngIf="cityControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field *ngIf="provinces.length > 0" class="fc200">
                            <mat-label>{{ 'global.province' | translate }}</mat-label>
                            <mat-select [formControl]="provinceControl"  [errorStateMatcher]="matcher" required>
                                <mat-option *ngFor="let province of provinces" [value]="province.value">{{ language === 'fr' ? province.name_fr : province.name }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="provinceControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field> 
                        &nbsp;
                        <mat-form-field class="fc125" required>
                            <mat-label>{{ 'global.postalcode' | translate }}</mat-label>
                            <input matInput [formControl]="postalCodeControl" [errorStateMatcher]="matcher" required/>
                            <mat-error *ngIf="postalCodeControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                            <mat-error *ngIf="postalCodeControl.hasError('pattern')"[innerHTML]="'global.postalcodeinvalid' | translate"></mat-error>
                        </mat-form-field>
                    </div>
                      <!-- <mat-form-field class="fc300">
                        <mat-label>{{ 'rx.pharmacy' | translate }}</mat-label>
                        <mat-select [formControl]="faxControl" required [(value)]="this.appSettings.pharmacies[0].faxnumber">
                          <mat-option [value]="appSettings.pharmacies[0].faxnumber">{{ appSettings.pharmacies[0].name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="faxControl.hasError('required')"
                          [innerHTML]="'rx.errors.faxnumberrequired' | translate"></mat-error>
                      </mat-form-field> -->
                    </form>
              </div>
            <div class="button_container" align="end">
              <button mat-button [disabled]="!clinicForm.valid" (click)="nextClicked($event)">{{ 'rx.next' | translate }}</button>
            </div>
          </mat-step>

        <mat-step label="Locations" completed="false">
          <div>
            <div *ngIf="locationForm.get('locations')['controls'].length === 0" style="margin-top: 10px">
                <p>There are currently no additional locations setup for this clinic.  To add an additional location (other than the primary location setup in the previous screen, click the "Add Location" button located below.</p>
            </div>
            <form [formGroup]="locationForm">
            <div formArrayName="locations" *ngFor="let location of locationForm.get('locations')['controls']; let locIdx = index">
                <mat-divider *ngIf="locIdx !== 0" class="location_divider"></mat-divider>
            <div [formGroupName]="locIdx">
                <div>
                    <mat-form-field class="fc250">
                        <mat-label>{{ 'clinic.locationname' | translate }}</mat-label>
                        <input cdkFocusInitial matInput formControlName='name' required />
                        <mat-error *ngIf="location.get('name').hasError('required')" [innerHTML]="'global.required' | translate">
                            <mat-error *ngIf="location.get('name').hasError('required')">
                                {{ 'global.required' | translate }}
                            </mat-error>
                        </mat-error>
                      </mat-form-field> 
                      &nbsp;
                    <mat-form-field class="fc125">
                        <mat-label>{{ 'global.phone' | translate }}</mat-label>
                        <input matInput formControlName='phone_number' required/>
                        <!-- <mat-icon matSuffix>phone</mat-icon> -->
                        <mat-error *ngIf="location.get('phone_number').hasError('required')">
                            {{ 'global.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="location.get('phone_number').hasError('pattern')">
                            {{ 'global.invalidphone' | translate }}
                        </mat-error>
                    </mat-form-field>
                    &nbsp;                        <mat-form-field class="fc125">
                        <mat-label>{{ 'global.fax' | translate }}</mat-label>
                        <input matInput formControlName='fax_number'/>
                        <!-- <mat-icon matSuffix>phone</mat-icon> -->
                        <!-- <mat-error *ngIf="faxNumberControl.hasError('required')">
                            {{ 'global.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="faxNumberControl.hasError('pattern')">
                            {{ 'global.invalidphone' | translate }}
                        </mat-error> -->
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="fc150">
                        <mat-label>{{ 'global.address' | translate }}</mat-label>
                        <input matInput formControlName='address' required />
                        <mat-error *ngIf="location.get('address').hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field class="fc150">
                        <mat-label>{{ 'global.address2' | translate }}</mat-label>
                        <input matInput formControlName='address2' />
                    </mat-form-field>
                    &nbsp;            
                    <mat-form-field class="fc125">
                        <mat-label>{{ 'global.city' | translate }}</mat-label>
                        <input matInput formControlName='city' required />
                        <mat-error *ngIf="location.get('city').hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field *ngIf="provinces.length > 0" class="fc125">
                        <mat-label>{{ 'global.province' | translate }}</mat-label>
                        <mat-select formControlName='province'  required>
                            <mat-option *ngFor="let province of provinces" [value]="province.value">{{ language === 'fr' ? province.name_fr : province.name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="location.get('province').hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                    </mat-form-field> 
                    &nbsp;
                    <mat-form-field class="fc100">
                        <mat-label>{{ 'global.postalcode' | translate }}</mat-label>
                        <input matInput formControlName='postal_code' required/>
                        <mat-error *ngIf="location.get('postal_code').hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                        <mat-error *ngIf="location.get('postal_code').hasError('pattern')" [innerHTML]="'global.postalcodeinvalid' | translate"></mat-error>
                    </mat-form-field>
                </div>
 
            </div>
            </div>
            </form>
          </div>
          <div class="button_container" align="end">
            <button *ngIf="locationForm.get('locations')['controls'].length > 0" mat-button color="warn" type="button" (click)="removeLocationForm()"><mat-icon>wrong_location</mat-icon>&nbsp;Remove Location</button>&nbsp;
            <button mat-button color="accent" type="button" (click)="addLocationForm()"><mat-icon>add_location_alt</mat-icon>&nbsp;Add Location</button>&nbsp;&nbsp;
            <button mat-button matStepperPrevious>{{ 'rx.back' | translate }}</button>&nbsp;
            <button mat-button [disabled]="!locationForm.valid" (click)="nextClicked($event)">{{ 'rx.next' | translate }}</button>
          </div>
        </mat-step>

        <mat-step label="Doctors" completed="false">
            <div>
              <div *ngIf="doctorForm.get('doctors')['controls'].length === 0" style="margin-top: 10px">
                  <p>There are currently no doctors setup for this clinic.  To add an additional doctors, click the "Add Doctor Form" button for each doctor you would like to add.</p>
              </div>
              <form [formGroup]="doctorForm">
              <div formArrayName="doctors" *ngFor="let doctor of doctorForm.get('doctors')['controls']; let docIdx = index">
                                   
            <mat-divider *ngIf="docIdx !== 0" class="location_divider"></mat-divider>
              <div [formGroupName]="docIdx">
                  <div>
                      <mat-form-field class="fc150">
                          <mat-label>{{ 'doctors.firstname' | translate }}</mat-label>
                          <input matInput cdkFocusInitial formControlName='first_name' required />
                          <mat-error *ngIf="doctor.get('first_name').hasError('required')" [innerHTML]="'global.required' | translate">aaa</mat-error>
                        </mat-form-field> 
                        &nbsp;
                      <mat-form-field class="fc200">
                          <mat-label>{{ 'doctors.lastname' | translate }}</mat-label>
                          <input matInput formControlName='last_name' [errorStateMatcher]="matcher" pattern required/>
                          <!-- <mat-icon matSuffix>phone</mat-icon> -->
                          <mat-error *ngIf="doctor.get('last_name').hasError('required')">
                              {{ 'global.required' | translate }}
                          </mat-error>
                      </mat-form-field>
                      &nbsp;                       
                     <mat-form-field class="fc125">
                          <mat-label>{{ 'doctors.licensenumber' | translate }}</mat-label>
                          <input matInput formControlName='license_number' [errorStateMatcher]="matcher" required/>
                          <!-- <mat-icon matSuffix>phone</mat-icon> -->
                          <mat-error *ngIf="doctor.get('license_number').hasError('required')">
                              {{ 'global.required' | translate }}
                          </mat-error>
                      </mat-form-field>
                      &nbsp;
                      <mat-form-field class="fc125" >
                          <mat-label>{{ 'global.language' | translate }}</mat-label>
                          <mat-select formControlName='language' [value]="'fr'" [errorStateMatcher]="matcher" required>
                            <mat-option value="fr">Français</mat-option>
                            <mat-option value="en">English</mat-option>
                          </mat-select>
                          <mat-error *ngIf="doctor.get('language').hasError('required')">
                            {{ 'global.required' | translate }}
                        </mat-error>
                        </mat-form-field>
                  </div>
              </div>
              </div>
              </form>
            </div>
            <div class="button_container" align="end">
              <button *ngIf="doctorForm.get('doctors')['controls'].length > 1" mat-button color="warn" type="button" (click)="removeDoctorForm()"><mat-icon>person_remove_alt_1</mat-icon>&nbsp;Remove Doctor Form</button>&nbsp;
              <button mat-button color="accent" type="button" (click)="addDoctorForm()"><mat-icon>person_add_alt_1</mat-icon>&nbsp;Add Doctor Form</button>&nbsp;&nbsp;
              <button mat-button matStepperPrevious>{{ 'rx.back' | translate }}</button>&nbsp;
              <button mat-button [disabled]="!doctorForm.valid" (click)="nextClicked($event)">{{ 'rx.next' | translate }}</button>
            </div>
          </mat-step>
  
            <mat-step label="Clinic Admin User" completed="false">
              <div>
                <form [formGroup]="adminUserForm">
                    <div>
                        <mat-form-field class="fc300">
                          <mat-label>{{ 'global.email' | translate }}</mat-label>
                          <input matInput [formControl]="userEmailControl" [errorStateMatcher]="matcher" required />
                          <!-- <mat-icon matSuffix>mail</mat-icon> -->
                          <mat-error *ngIf="userEmailControl.hasError('email') && !userEmailControl.hasError('required')" [innerHTML]="'global.invalidemail' | translate"></mat-error>
                          <mat-error *ngIf="userEmailControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                      </mat-form-field>
                      &nbsp;
                      <mat-form-field class="fc100" >
                          <mat-label>{{ 'global.language' | translate }}</mat-label>
                          <mat-select [formControl]="userLanguageControl" [errorStateMatcher]="matcher" required>
                            <mat-option value="fr">Français</mat-option>
                            <mat-option value="en">English</mat-option>
                          </mat-select>
                          <mat-error *ngIf="userLanguageControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="fc200">
                            <mat-label>{{ 'global.firstname' | translate }}</mat-label>
                            <input matInput cdkFocusInitial [formControl]="userFirstNameControl" [errorStateMatcher]="matcher" required/>
                            <mat-error *ngIf="userFirstNameControl.hasError('required')"
                              [innerHTML]="'global.required' | translate"></mat-error>
                          </mat-form-field>
                          &nbsp;                       
                          <mat-form-field class="fc200">
                            <mat-label>{{ 'global.lastname' | translate }}</mat-label>
                            <input matInput [formControl]="userLastNameControl" [errorStateMatcher]="matcher" required/>
                            <mat-error *ngIf="userLastNameControl.hasError('required')"
                              [innerHTML]="'global.required' | translate"></mat-error>
                          </mat-form-field>
                    </div>
                    </form>
                    <div class="mat-caption" class="emailexists"><span *ngIf="emailExists">{{ 'global.emailexists' | translate }}</span>&nbsp;</div>
              </div>
            <div class="button_container" align="end">
                <button mat-button matStepperPrevious [disabled]="isSavingClinic">{{ 'rx.back' | translate }}</button>&nbsp;
              <button mat-flat-button [disabled]="!adminUserForm.valid || isSavingClinic || emailExists" color="accent" (click)="completeSetup()">Complete Setup</button>
            </div>
          </mat-step>

          <div *ngIf="">

          </div>
      </mat-horizontal-stepper>
    </mat-dialog-content>
    <!-- <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ 'batch.cancel' | translate }}</button>
     </mat-dialog-actions> -->
  </ng-template>

  <ng-template #clinicSuppliesDialog>
    <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 matDialogTitle class="title">{{ 'clinic.supplies.title' | translate }}</h2>
    <mat-dialog-content class="mat-typography">
      <mat-selection-list (selectionChange)="supplyChange($event)">
        <ng-container *ngFor="let supply of clinicSupplies; let supplyIdx = index">
            <h2 *ngIf="categoryDivider(supply.category)">{{ supply.category }}</h2>
            <mat-list-option [selected]="supply.clinics ? supply.clinics.includes(this.selectedClinic.clinic_id) : false" [value]="supply">
            {{supply.name}}
        </mat-list-option>
        </ng-container>
     </mat-selection-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button cdkFocusInitial mat-button mat-dialog-close>{{ 'global.finished' | translate }}</button>
    </mat-dialog-actions>
</ng-template>
