import { Component, OnInit } from '@angular/core';
import { PatientService } from '../patient.service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { first, takeUntil } from 'rxjs/operators';
import { Patient } from 'src/app/shared/models/patient';
import { User } from 'functions/src';
import { DataService } from 'src/app/services/data.service';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { chunk } from 'lodash';
import { UtilityService } from 'src/app/services/utility.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { OrderService } from 'src/app/order/order.service';

@Component({
  selector: 'app-patient-upload',
  templateUrl: './patient-upload.component.html',
  styleUrls: ['./patient-upload.component.scss']
})
export class PatientUploadComponent implements OnInit {
  ngUnsubscribe: Subject<void> = new Subject();

  loading: boolean = false;
  user: User;
  appSettings: any;
  patients: Patient[] = [];
  header = true;

  doctors: any;
  medications: any[] = [];
  eyes: any[] = [];

  constructor(
    public patientService: PatientService,
    private ngxCsvParser: NgxCsvParser,
    private auth: AuthService,
    private db: DataService,
    private afs: AngularFirestore,
    private utility: UtilityService,
    public spinner: SpinnerService,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.appSettings = await this.db.getAppSettings().pipe(first()).toPromise();
    this.appSettings['medications'].forEach(medication => {
        if (medication.provinces.includes(this.user.clinic_province)) {
          this.medications.push(medication);
        }
    });
    this.eyes = this.appSettings['eyes'];
    this.doctors = await this.orderService.getDoctorsByClinic(this.user.clinic_id).pipe(first()).toPromise();
  }

  selectCSVFile(fileInputEvent: any)  {
    const csvFile = fileInputEvent.target.files[0];
    this.loading = true;
    this.ngxCsvParser.parse(csvFile, { header: this.header, delimiter: ',' })
    .pipe()
    .subscribe((patients: Array<Patient>) => {
      this.patients = patients.map(patient => this.validatePatientData(patient));
      this.loading = false;
    }, (error: NgxCSVParserError) => {
      console.log('Error', error);
      this.loading = false;
    });
  }

  validatePatientData(patient: any) {
    if (patient.healthcard) {
      patient.last_name = patient.last_name ? patient.last_name : patient['maiden'] ? patient['maiden'] : '';
      patient['insurance_criteria'] = [];
      patient['insurance_criteria'].push("ramq");
      patient.phone_number = this.validateE164(patient.phone_number);
      patient.language = patient.language.toLowerCase();
      // let doc = patient.doc;
      // patient.doctor = this.doctors.filter(d => d.last_name.toLowerCase() === doc.toLowerCase());
      // let med = patient.med.replace(' Injections','').replace(' Injection','').replace(' injections','').replace(' injection','');
      // patient.medication = this.medications.filter(m => m.name.toLowerCase() === med.toLowerCase());
    }
    return patient;
  }

  async uploadPatients() {
    this.spinner.spin$.next(true);
    const createdAt = this.utility.timestamp;
    const createdBy = `${this.user.first_name} ${this.user.last_name}`;
    const createdById = this.user.id;

    console.log("Patients to upload: ", this.patients.length);
    let count = 1;
    const batches = chunk(this.patients, 200).map(patients => {
      console.log("Batch: ", count);
      const batch = this.afs.firestore.batch();
      patients.forEach(patient => {
          delete patient['doc'];
          delete patient['med'];
          patient.id = this.afs.createId();
          patient.address = patient.address ? patient.address : null;
          patient.address2 = patient.address2 ? patient.address2 : null;
          patient.city = patient.city ? patient.city : null;
          patient.province = patient.province ? patient.province : 'QC';
          patient.postal_code = patient.postal_code ? patient.postal_code : null;
          patient.phone_number = patient.phone_number ? this.validateE164(patient.phone_number) : null;
          patient.phone_number_cell = patient.phone_number_cell ? this.validateE164(patient.phone_number_cell) : null;
          patient.email = patient.email ? patient.email : null;
          patient.language = patient.language ? patient.language : null;
          patient.gender = patient.gender ? patient.gender : null;
          patient.doctor = patient.doctor ? patient.doctor : null;
          patient.medication = patient.medication ? patient.medication : null;
          patient.createdAt = createdAt;
          patient.createdBy = createdBy;
          patient.createdById = createdById;
          patient.updatedAt = createdAt;
          patient.updatedBy = createdBy;
          patient.updatedById = createdById;
          patient.full_name = patient.first_name + ' ' + patient.last_name;

          batch.set(this.afs.firestore.doc(`/clinics/${this.user.clinic_id}/patients/${patient.id}`),{...patient});
        });
      count++;
      return batch.commit()
    });
    console.log("Patient write started");
    await Promise.all(batches)
    console.log("Patient write complete");
    this.spinner.spin$.next(false);
  }

  validateE164(phone_number: string) {
    if (phone_number === '') return null;
    phone_number = phone_number.replace('-', '').replace('-', '');
    if (phone_number.startsWith('+1')) return phone_number;
    if (phone_number.startsWith('+')) return phone_number.replace('+', '+1');
    return `+1${phone_number}`;
  }

}
