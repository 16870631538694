import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad';
import { Subject } from 'rxjs';
import { DataService } from '../services/data.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-doctor-signature',
  templateUrl: './doctor-signature.component.html',
  styleUrls: ['./doctor-signature.component.scss']
})
export class DoctorSignatureComponent implements OnInit {
  @ViewChildren(SignaturePad) signaturePads:QueryList<SignaturePad>;

  ngUnsubscribe: Subject<void> = new Subject();

  language: string;
  clinicId: string;
  doctorId: string;
  licenseNumber: string;
  doctorName: string;

  doctorForm: FormGroup;
  licenseNumberControl = new FormControl('');
  signature: string;
  complete = false;
  message: string;

  matcher = new MyErrorStateMatcher();

  constructor(
    private aRoute: ActivatedRoute,
    private translate: TranslateService,
    private db: DataService
  ) { 
    this.language = this.aRoute.snapshot.params.language;
    this.clinicId = this.aRoute.snapshot.params.clinicId;
    this.doctorId = this.aRoute.snapshot.params.doctorId;
    this.licenseNumber = this.aRoute.snapshot.params.licenseNumber;
    this.doctorName = decodeURI(this.aRoute.snapshot.params.doctorName);

    this.translate.use(this.language);
  }

  ngOnInit() {
    this.doctorForm = new FormGroup({
      license_number: this.licenseNumberControl,
    })
  }

  async ngAfterViewInit() {
  }

  clearSignature(index) {
    let signaturePad = this.signaturePads.toArray()[index];
    signaturePad.clear();
    this.signature = null;
  }

  setExistingSignature(index, signature: string) {
    let signaturePad = this.signaturePads.toArray()[index];
    this.signature = signature;
    signaturePad.fromDataURL(signature);
  }

  drawStart(index) {
    console.log("Started drawing on signature", index);
  }

  drawComplete(index) {
    console.log("Ended drawing on signature", index);
    let signaturePad = this.signaturePads.toArray()[index];
    if(!signaturePad.isEmpty())  {
      this.signature = signaturePad.toDataURL();
    }
  }

  saveDoctor()  {
    let path = `clinics/${this.clinicId}/doctors/${this.doctorId}`;
    console.log('Path: ', path);
    this.db.updateDocument(path, { signature: this.signature }).then(() => {
      this.complete = true;
      this.message = 'Merci d`avoir rempli la demande de signature. Vous pouvez fermer cette fenêtre à tout moment. / Thank you for completing the signature request.  You may close this window at any time.';
    })
    .catch(() => {
      this.complete = false;
      this.message = 'Un problème est survenu lors de l`enregistrement de la signature. Veuillez réessayer. / There was a problem saving the signature.  Please try again.'
    })
  }

}
