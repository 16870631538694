<div>
        <mat-toolbar color="accent">
            <span>Patients</span>
            <ng-container *ngIf="user">
                <span *ngIf="user.injectionclinic">&nbsp;-&nbsp;Currently assigning patients to {{ user.injectionclinic.name ? user.injectionclinic.name : '' }} {{ this.utility.formatDate(user.injectionclinic.clinicDate, 'YYYY-MM-DD') }}</span>
            </ng-container>
            <span class="fill-space"></span>
            <!-- <button mat-flat-button (click)="SaveView(grid)">{{ 'global.save' | translate }}</button>&nbsp; -->
            <button mat-flat-button (click)="AddPatient()">{{ 'patients.newpatient' | translate }}</button>
            <!-- <ng-container *ngIf="user">
                &nbsp;<button mat-flat-button color="warn" *ngIf="user.last_name === 'Spadzinski'" (click)="DeletePatients()">Delete</button>
            </ng-container> -->
        </mat-toolbar>
        <kendo-grid
            #grid
            [kendoGridBinding]="patients"
            kendoGridSelectBy="id"
            [selectedKeys]="mySelection"
            [pageSize]="15"
            [pageable]="true"
            [sortable]="{
                allowUnsort: true,
                mode: 'single'
            }"
            [sort]="sort"
            [filterable]="true"
            [groupable]="false"
            [reorderable]="true"
            [resizable]="true"
            [columnMenu]="{ filter: true }"
            [loading]="patientService.loading"
        >
        <kendo-grid-messages filterBooleanAll="{{ 'patients.filter.all' | translate }}" filterIsTrue="{{ 'patients.filter.true' | translate }}" filterIsFalse="{{ 'patients.filter.false' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
        </kendo-grid-messages>
        <!-- <kendo-grid-column  [includeInChooser]="false" [sortable]="true" [filterable]="false" field="last_rx" title="{{ 'shell.rxorders' | translate }}" [width]="207">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button mat-button color="primary" [matTooltip]="addTooltip(dataItem.last_rx)" matTooltipClass="multiline-tooltip" [disabled]="!dataItem.last_rx" (click)="ReorderRx(dataItem);">{{ 'patients.reorder' | translate }}</button>
                <button mat-button color="accent" (click)="AddRx(dataItem);">{{ 'patients.addrx' | translate }}</button>
            </ng-template>
        </kendo-grid-column> -->

        <kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem>
                   <!--<button style="width: 30px !important;" mat-button color="warn" (click)="DeletePatient(dataItem.id)"><mat-icon>delete</mat-icon></button> -->
                <a mat-button [matMenuTriggerFor]="sysAdminMenu">{{ 'shell.actions' | translate }}</a>
                <mat-menu #sysAdminMenu="matMenu">
                  <a mat-menu-item mat-menu-item (click)="createEnrollments(dataItem);"><mat-icon>picture_as_pdf</mat-icon>Create Enrollments</a>
                  <a mat-menu-item mat-menu-item (click)="EditPatient(dataItem);"><mat-icon>edit</mat-icon>Edit Patient</a>
                  <a mat-menu-item mat-menu-item (click)="DeletePatient(dataItem.id)"><mat-icon>delete</mat-icon>Delete Patient</a>
                </mat-menu>
                <button *ngIf="user.injectionclinic" [disabled]="!dataItem.isReadyForRx" mat-button color="accent" (click)="addRx(dataItem);">Add To Injection Clinic</button>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="consentSigned" filter="boolean" title="{{ 'patients.consent' | translate }}" [width]="154">
            <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container *ngIf="dataItem.consentSigned">
                    <a mat-button *ngIf="dataItem.consentPdf" mat-button [href]="dataItem.consentPdf.url" target="_blank">{{dataItem.consentDate.toDate() | date: 'yyyy-MM-dd'}}</a>
                    <span *ngIf="!dataItem.consentPdf">{{dataItem.consentDate.toDate() | date: 'yyyy-MM-dd'}}</span>
                </ng-container>
                <ng-container *ngIf="!dataItem.consentSigned">
                    <button mat-button (click)="signConsent(dataItem);">{{ 'patients.consent' | translate }}</button>
                </ng-container>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="last_name" title="{{ 'patients.lastname' | translate }}" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.last_name | titlecase }}
            </ng-template> 
        </kendo-grid-column>
        <kendo-grid-column field="first_name" title="{{ 'patients.firstname' | translate }}" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.first_name | titlecase }}
            </ng-template> 
        </kendo-grid-column>
        <kendo-grid-column field="dob" title="{{ 'patients.dob' | translate }}" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.dob | date: 'yyyy-MM-dd'}}
            </ng-template> 
        </kendo-grid-column>
        <!-- <kendo-grid-column field="dob" filter="date" format="{0:yyyy-MM-dd}" title="{{ 'patients.dob' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.dob | date: 'yyyy-MM-dd'}}
            </ng-template>
        </kendo-grid-column> -->
        <kendo-grid-column field="diagnosis" title="{{ 'patients.diagnosis' | translate }}" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem.diagnosis }}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="medication.name" title="{{ 'rx.medication' | translate }}" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span *ngIf="dataItem.medication">{{ dataItem.medication.name }}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="eye" title="{{ 'rx.eye' | translate }}" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span translate>{{ dataItem.eye }}</span>
            </ng-template>
          </kendo-grid-column>
        <kendo-grid-column field="chart_id" filter="numeric" title="{{ 'patients.emrid' | translate }}" [width]="150"></kendo-grid-column>
        <kendo-grid-column field="healthcard"  title="{{ 'patients.healthcard' | translate }}" [width]="200"></kendo-grid-column>
        <!-- <kendo-grid-column field="dob" title="{{ 'patients.rxrenewaldate' | translate }}" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.rxRenewalDate | date: 'yyyy-MM-dd'}}
            </ng-template> 
        </kendo-grid-column> -->
        <!-- <kendo-grid-column field="phone_number" title="{{ 'patients.phone' | translate }}"></kendo-grid-column> -->

      </kendo-grid>
      </div>    

      <ng-template #callDeleteConfirmDialog>
        <h2 matDialogTitle>{{ 'patients.delete.title' | translate }}</h2>
        <mat-dialog-content>
            {{ 'patients.delete.message' | translate }}
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
            <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
        </mat-dialog-actions>
    </ng-template>

    <ng-template #injectionAddConfirmDialog>
        <h2 matDialogTitle><span>{{ 'injectionclinics.addpatient.title' | translate }}</span></h2>
        <mat-dialog-content>
            <div style="margin-bottom: 7px;">
                <span>{{ 'injectionclinics.addpatient.message' | translate }}</span>
            </div>
            <div *ngIf="user.clinic.requiresAppointmentTime" class="ngx-timepicker-field-example">
                <!-- <ngx-timepicker-field [(ngModel)]="appointmentTime" [toggleIcon]="icon" [defaultTime]="'08:00 am'"></ngx-timepicker-field>
                <ng-template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
                        <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,16H7.995 C7.445,16,7,15.555,7,15.005v-0.011C7,14.445,7.445,14,7.995,14H14V5.995C14,5.445,14.445,5,14.995,5h0.011 C15.555,5,16,5.445,16,5.995V16z"/>
                    </svg>
                </ng-template>
            </div>
            <br/>
            <div> -->
                <mat-form-field appearance="outline" class="fc150" >
                    <mat-label>{{'injectionclinics.appointmenttime' | translate}}</mat-label>
                    <!-- onfocus="this.select()" -->
                    <input
                        cdkFocusInitial
                        matInput
                        aria-label="appointment time"
                        [ngxTimepicker]="defaultValue"
                        [(ngModel)]="appointmentTime"
                     />

                     <mat-icon matSuffix>schedule</mat-icon>
                </mat-form-field>
                <ngx-material-timepicker #defaultValue>
                </ngx-material-timepicker>
            </div>
            <div>
                <mat-form-field class="fc150" appearance="outline">
                    <mat-label>{{ 'rx.medication' | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedPatient.medication" [compareWith]="compareMedications">
                      <mat-option *ngFor="let medication of medications" [value]="medication">{{ medication.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div>
                <mat-form-field class="fc150" appearance="outline">
                    <mat-label>{{ 'rx.eye' | translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedPatient.eye">
                      <mat-option *ngFor="let eye of eyes" [value]="eye['value']">{{ user.language === 'fr' ? eye['name_fr'] : eye['name'] }}</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div>
                <mat-form-field class="fc400" appearance="outline">
                    <mat-label>{{ 'rx.notes' | translate }}</mat-label>
                    <textarea matInput rows="7" [(ngModel)]="note"></textarea>
                  </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
            <button *ngIf="!user.clinic.requiresAppointmentTime" mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
            <button *ngIf="user.clinic.requiresAppointmentTime" [disabled]="user.clinic.requiresAppointmentTime && !appointmentTime" mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
        </mat-dialog-actions>
    </ng-template>

<ng-template #patientConsentDialog>
    <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 matDialogTitle>{{ 'patients.consent' | translate }}<span *ngIf="selectedPatient"> - {{ selectedPatient.full_name }}</span></h2>
    <mat-dialog-content class="mat-typography">
            <div>
                <mat-checkbox class="example-margin" [(ngModel)]="termsone"><span class="french_consent_text">J'ai posé toutes les questions voulues au sujet des particularités de mon traitement.&nbsp;-&nbsp;</span><span class="english_consent_text">I asked all the questions about the specifics of my treatment.</span></mat-checkbox>
                <mat-checkbox class="example-margin" [(ngModel)]="termstwo"><span class="french_consent_text">J'ai donné accès à mes informations personnelles et consens à ce que la pharmacie puisse communiquer avec mon médecin concernant ma santé lorsque nécessaire.&nbsp;-&nbsp;</span><span class="english_consent_text">I have given access to my personal information and consent that the pharmacy can communicate with my doctor regarding my health when necessary.</span></mat-checkbox>
                <mat-checkbox class="example-margin" [(ngModel)]="termsthree"><span class="french_consent_text">Je consens à ce qu'elle puisse communiquer avec mon assureur au sujet d'une réclamation.&nbsp;-&nbsp;</span><span class="english_consent_text">I give consent to the pharmacy to communicate with my insurer regarding a claim.</span></mat-checkbox>
                <mat-checkbox class="example-margin" [(ngModel)]="termsfour"><span class="french_consent_text">On m'a offert la possibilité de faire affaire avec ma pharmacie habituelle.&nbsp;-&nbsp;</span><span class="english_consent_text">I was offered the possibility of doing business with my usual pharmacy.</span></mat-checkbox>
                <mat-checkbox class="example-margin" [(ngModel)]="termsfive"><span class="french_consent_text">Je consens de manière libre et éclairée à ce que cette prescription soit exécutée par la Pharmacie Éric Beaulieu ayant une entente administrative avec la clinique.&nbsp;-&nbsp;</span><span class="english_consent_text">I freely and fully consent to this prescription being carried out by Pharmacie Éric Beaulieu having an administrative agreement with the clinic.</span></mat-checkbox>
            </div>

            <div style="border-bottom: 2px dashed black;">
                <signature-pad [id]="'d0'" [options]="{'minWidth': 2, 'canvasWidth': 700,'canvasHeight': 200 }"
                    (onBeginEvent)="drawStart(0)" (onEndEvent)="drawComplete(0)"></signature-pad>
            </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="warn" [disabled]="!signature" (click)="clearSignature(0);">{{ 'rx.clearsignature' | translate }} </button>
        <button mat-button mat-dialog-close>{{ 'batch.cancel' | translate }}</button>
        <button mat-button color="accent" [disabled]="!termsone || !termstwo || !termsthree || !termsfour || !termsfive || !this.signature" (click)="submitConsent()">{{ 'rx.send' | translate
            }}</button>
    </mat-dialog-actions>
</ng-template>


<ng-template #availableEnrollments>
    <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 matDialogTitle>{{ 'patients.forms.title' | translate }}</h2>
    <mat-dialog-content>
        <!-- <p>
            {{ 'patients.forms.message' | translate }}
        </p> -->
        <div>
            <div class="form-button-container" *ngIf="showPublicSpecialPriorAuthorization()">
                <img src="../../../assets/images/logo_quebec.png" style="width: 200px;"><br/>
                <a *ngIf="showEyleaEnrollmentForm()" mat-flat-button style="background-color: #265792 !important; color: white !important;" href="/assets/forms/eylea_qc.pdf" target="_blank">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.ramq' | translate }}
                </a>
                <a *ngIf="showLucentisEnrollmentForm()" mat-flat-button style="background-color: #265792 !important; color: white !important;" href="/assets/forms/lucentis_qc.pdf" target="_blank">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.ramq' | translate }}
                </a>
            </div>
            <!-- <div class="form-button-container" *ngIf="showPublicSpecialPriorAuthorization()">
                <img src="../../../assets/images/logo_medicare.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #1E4C42 !important; color: white !important;" (click)="createNBSA()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.medicare' | translate }}
                </button>
            </div>
            <div class="form-button-container" *ngIf="showPrivateSpecialPriorAuthorization()">
                <img src="../../../assets/images/logo_medavie_bluecross.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #3094d7 !important; color: white !important;" (click)="createNBSA()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.bluecross' | translate }}
                </button>
            </div> -->
            <div class="form-button-container" *ngIf="showEyleaEnrollmentForm()">
                <img src="../../../assets/images/eylea_program_logo.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #00C0F3 !important; color: white !important;" (click)="createEyeQEnrolment()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.eyeq' | translate }}
                </button>
            </div>
            <div class="form-button-container" *ngIf="showLucentisEnrollmentForm()">
                <img src="../../../assets/images/lucentis_program_logo.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #E8903D !important; color: white !important;" (click)="createLucentisEnrolment()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.lucentis' | translate }}
                </button>
            </div>
            <div class="form-button-container" *ngIf="showBeovuEnrollmentForm()">
                <img src="../../../assets/images/beovu_program_logo.png" style="width: 200px;"><br/>
                <button mat-flat-button style="background-color: #007843 !important; color: white !important;" (click)="createBeovuEnrolment()">
                    <mat-icon>download</mat-icon>
                    {{ 'forms.beovu' | translate }}
                </button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>