<div>
    <mat-toolbar color="accent">
        <span>{{ 'supply.title' | translate }}</span>
        <span class="fill-space"></span>
        <ng-container *ngIf="supplies">
            <button mat-flat-button (click)="openAddSupplyDialog()">{{
                'supply.add' | translate }}</button>
        </ng-container>
    </mat-toolbar>
    <kendo-grid [kendoGridBinding]="supplies" kendoGridSelectBy="id" [pageable]="false" [sortable]="true"
        [filterable]="true" [groupable]="true" [group]="groups" (groupChange)="groupChange($event)" [reorderable]="true"
        [resizable]="true" [columnMenu]="{ filter: true }" [loading]="loading">
        <kendo-grid-messages noRecords="{{ 'global.norecords' | translate }}" pagerOf=":"
            pagerItems="{{ 'global.items' | translate }}">
        </kendo-grid-messages>
        <!-- <kendo-grid-column field="din" title="{{ 'supplyorder.din' | translate }}"></kendo-grid-column> -->
        <kendo-grid-column field="name" title="{{ 'supplyorder.name' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="category" title="{{ 'supplyorder.category' | translate }}" [width]="250"></kendo-grid-column>
        <kendo-grid-column field="size" title="{{ 'supplyorder.size' | translate }}" [width]="175"></kendo-grid-column>
        <kendo-grid-column field="strength" title="{{ 'supplyorder.strength' | translate }}" [width]="175"></kendo-grid-column>
        <kendo-grid-column field="unit" title="{{ 'supplyorder.unit' | translate }}" [width]="175"></kendo-grid-column>
        <kendo-grid-column field="price" filter="numeric" title="{{ 'supplyorder.price' | translate }}" [width]="175"></kendo-grid-column>
        <kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="250">
            <ng-template kendoGridCellTemplate let-dataItem>
                <button mat-button color="primary" (click)="openAddSupplyDialog(dataItem)"><mat-icon>edit</mat-icon></button>
                <button mat-button color="warn" (click)="deleteSupply(dataItem.id)"><mat-icon>delete</mat-icon></button>
            </ng-template>
          </kendo-grid-column>
    </kendo-grid>
</div>


<ng-template #deleteConfirmDialog>
    <h2 matDialogTitle>Delete?</h2>
    <mat-dialog-content>
        Are you sure you want to do this?
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
        <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #orderSubmitConfirmDialog>
    <h2 matDialogTitle class="title">{{ 'rx.dialog.sendorder.title' | translate }}</h2>
    <mat-dialog-content>
        {{ 'rx.dialog.sendorder.message' | translate }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
        <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #addDialog>
    <h2 matDialogTitle class="title"><span *ngIf="!this.selectedSupply">{{ 'supply.add' | translate }}</span><span *ngIf="this.selectedSupply">{{ this.selectedSupply.name }}</span></h2>
    <mat-dialog-content>
         <div>
            <form [formGroup]="supplyForm">
                    <div>
                        <mat-form-field class="fc500">
                            <mat-label>{{ 'supplyorder.name' | translate }}</mat-label>
                            <input matInput [formControl]="nameControl" required />
                            <mat-error *ngIf="nameControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                          </mat-form-field> 
                    </div>
                    <div>
                          <mat-form-field class="fc300">
                            <mat-label>{{ 'supplyorder.category' | translate }}</mat-label>
                            <input type="text"
                                   placeholder="Pick one"
                                   matInput
                                   [formControl]="categoryControl"
                                   [matAutocomplete]="auto" required>
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option *ngFor="let category of categories" [value]="category">
                                {{category}}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="categoryControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                          &nbsp;                       
                          <mat-form-field class="fc200">
                               <mat-label>{{ 'supplyorder.din' | translate }}</mat-label>
                               <input matInput [formControl]="dinControl" [errorStateMatcher]="matcher" />
                               <!-- <mat-icon matSuffix>phone</mat-icon> -->
                               <mat-error *ngIf="dinControl.hasError('required')"
                               [innerHTML]="'global.required' | translate"></mat-error>
                          </mat-form-field>
                        </div>
                        <div>
                       <mat-form-field class="fc125">
                            <mat-label>{{ 'supplyorder.size' | translate }}</mat-label>
                            <input matInput [formControl]="sizeControl" [errorStateMatcher]="matcher"/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="sizeControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                        &nbsp;
                        <mat-form-field class="fc125">
                            <mat-label>{{ 'supplyorder.strength' | translate }}</mat-label>
                            <input matInput [formControl]="strengthControl" [errorStateMatcher]="matcher"/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="strengthControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                        &nbsp;
                        <mat-form-field class="fc125">
                            <mat-label>{{ 'supplyorder.unit' | translate }}</mat-label>
                            <input matInput [formControl]="unitControl" [errorStateMatcher]="matcher"/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="unitControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                        &nbsp;
                        <mat-form-field class="fc125">
                            <mat-label>{{ 'supplyorder.price' | translate }}</mat-label>
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput type="number" [formControl]="priceControl" [errorStateMatcher]="matcher"/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="priceControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                    </div>
            </form>
         </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button cdkFocusInitial matDialogClose="Cancel">{{ 'global.cancel' | translate }}</button>
        <button mat-flat-button color="accent" [disabled]="!supplyForm.valid" matDialogClose="yes" (click)="saveSupply()">{{ 'global.save' | translate }}</button>
    </mat-dialog-actions>
  </ng-template>
