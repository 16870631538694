import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DataResult, GroupDescriptor, process } from '@progress/kendo-data-query';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { Rx } from 'src/app/shared/models/rx';
import { User } from 'src/app/shared/models/user';
import { AdminService } from '../../admin-service.service';
import { SignaturePad } from 'angular2-signaturepad';
import { MatStepper } from '@angular/material/stepper';
import { CreatedBy, RxOrder, SupplyOrder } from 'src/app/shared/models/order';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Clinic } from 'src/app/shared/models/clinic';
import { Location } from 'src/app/shared/models/location';
import { Subject } from 'rxjs';
import { Supply } from 'src/app/shared/models/supply';
import { SpinnerService } from 'src/app/services/spinner.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-supply-list',
  templateUrl: './supply-list.component.html',
  styleUrls: ['./supply-list.component.scss']
})
export class SupplyListComponent implements OnInit, OnDestroy {
  @ViewChild('deleteConfirmDialog') deleteConfirmDialog: TemplateRef<any>;
  @ViewChild('submitDialog') submitDialog: TemplateRef<any>;
  @ViewChild('addDialog') addDialog: TemplateRef<any>;

  ngUnsubscribe: Subject<void> = new Subject();
  supplies: any[] = [];
  categories: any[] = [];
  user: User;
  submitDialogRef: any;
  language: string;
  appSettings: any;
  selectedSupply: Supply;
  isEdit: boolean = false;

  public groups: GroupDescriptor[] = [
    { field: 'category', aggregates: [{aggregate: "count", field: 'name'}]}, 
  ];

  supplyForm: FormGroup;
  nameControl = new FormControl('', [
    Validators.required,
  ]);
  categoryControl = new FormControl('', [
    Validators.required,
  ]);
  dinControl  = new FormControl('')
  sizeControl = new FormControl('');
  unitControl = new FormControl('');
  strengthControl = new FormControl('');
  priceControl = new FormControl(null);
  matcher = new MyErrorStateMatcher();
  loading: boolean = false;

  public gridView: DataResult;
  constructor(
    private auth: AuthService,
    public adminService: AdminService,
    private matDialog: MatDialog,
    private utility: UtilityService,
    private router: Router,
    private translate: TranslateService,
    private db: DataService,
    private spinner: SpinnerService
  ) {
   }

  ngOnInit(): void {
    this.language = this.translate.currentLang;

    this.supplyForm = new FormGroup({
      din: this.dinControl,
      name: this.nameControl,
      category: this.categoryControl,
      size: this.sizeControl,
      unit: this.unitControl,
      strength: this.strengthControl,
      price: this.priceControl,
    });
  }

  ngOnDestroy() {
    console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.db.getAppSettings().subscribe(settings => {
      this.appSettings = settings;
    });

    this.loading = true;
    this.adminService.getSuppliesList().pipe(
      map((supplies) => {
        // patients.forEach((patient) => {
        //   patient['dob'] = DateTime.fromFormat(patient['dob'], 'yyyy-MM-dd');
        // });
        this.categories = [...new Set(supplies.map((supply) => supply.category))];
        return supplies;
      }),
      tap(() => this.loading = false),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(supplies => {
      this.supplies = supplies;
    })
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.gridView = process(this.supplies, { group: this.groups });
  }
 
  /********* Submit Order *****************/
  async openAddSupplyDialog(supply?: Supply) {
    if (supply) { 
      this.isEdit = true;
      this.selectedSupply = supply;
      this.supplyForm.patchValue(supply);
    } else {
      this.isEdit = false;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '600px';
    this.submitDialogRef = this.matDialog.open(this.addDialog, dialogConfig);
      this.submitDialogRef.afterClosed().subscribe(result => {
        console.log("Dialog close result: ", result);
        if (result !== undefined) {
            if (result !== 'Cancel') {
              
            } else {
              this.resetForm();
            }
        } else {
          this.resetForm();
        }
    })
  }

  saveSupply()  {
    this.spinner.spin$.next(true);
    let supply: Supply = <Supply>{};
    supply = this.supplyForm.value;
    console.log(supply);
    this.adminService.setSupply(supply, this.user)      
    .then(() => {
      this.utility.showSnackBar(this.translate.instant('supply.addsuccess'), 2000, 'center', 'top');
      this.resetForm();
      this.submitDialogRef.close();
    })
    .catch((err) => {
      this.utility.showSnackBar(this.translate.instant('supply.error.add'), 2000, 'center', 'top');
    })
    .finally(() => {
      this.spinner.spin$.next(false);
    })
    this.resetForm();
  }

  deleteSupply(supplyId: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    let dialogRef = this.matDialog.open(this.deleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
                this.spinner.spin$.next(true);
                this.adminService.deleteSupply(supplyId).then(() => {
                  this.utility.showSnackBar(this.translate.instant('supply.delete.success'), 3000, 'center', 'top');
                })
                .catch((err) => {
                  this.utility.showSnackBar(this.translate.instant('supply.error.delete'), 3000, 'center', 'top');
                })
                .finally(() => {
                  this.spinner.spin$.next(false);
                })
            } 
        }
    })
  }

  resetForm() {
    this.isEdit = false;
    this.selectedSupply = null;
    this.nameControl.setValue('');
    this.categoryControl.setValue('');
    this.dinControl.setValue('');
    this.sizeControl.setValue('');
    this.strengthControl.setValue('');
    this.unitControl.setValue('');
    this.priceControl.setValue(null);
  }
}
