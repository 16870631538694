import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from './admin/user/user-list/user-list.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { OrderListComponent } from './order/order/order-list/order-list.component';
import { RxListComponent } from './order/rx/rx-list/rx-list.component';
import { PatientAddComponent } from './patient/patient-add/patient-add.component';
import { PatientDetailComponent } from './patient/patient-detail/patient-detail.component';
import { PatientEditComponent } from './patient/patient-edit/patient-edit.component';
import { PatientListComponent } from './patient/patient-list/patient-list.component';
import { AngularFireAuthGuard, isNotAnonymous } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShellComponent } from './shared/shell/shell.component';
import { UtilityComponent } from './admin/utility/utility.component';
import { OrderDetailComponent } from './order/order/order-detail/order-detail.component';
import { SupplyOrderComponent } from './order/supply/supply-order/supply-order.component';
import { FaxReportComponent } from './reports/fax-report/fax-report.component';
import { RxReportComponent } from './reports/rx-report/rx-report.component';
import { ClinicListComponent } from './admin/clinic/clinic-list/clinic-list.component';
import { DoctorListComponent } from './admin/doctor/doctor-list/doctor-list.component';
import { PatientUploadComponent } from './patient/patient-upload/patient-upload.component';
import { ClinicScheduleComponent } from './reports/clinic-schedule/clinic-schedule.component';
import { SupplyListComponent } from './admin/supply/supply-list/supply-list.component';
import { PatientMobileComponent } from './patient-mobile/patient-mobile.component';
import { DoctorSignatureComponent } from './doctor-signature/doctor-signature.component';
import { InjectionClinicListComponent } from './injection-clinic/injection-clinic-list/injection-clinic-list.component';

export const redirectAnonymousTo = (redirect: any[]) => 
  pipe(isNotAnonymous, map(loggedIn => loggedIn || redirect)
);

const redirectUnauthorizedToLogin = () => redirectAnonymousTo(['/login']);

const routes: Routes = [
  { path: 'login', component: LoginPageComponent},
  { path: 'doctorsignature/:language/:clinicId/:doctorId/:licenseNumber/:doctorName', component: DoctorSignatureComponent},
  { path: '', component: ShellComponent, 
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/login'},
      { path: 'home', component: HomePageComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'patient-list', component: PatientListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'patient-add', component: PatientAddComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'patient-upload', component: PatientUploadComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'patient-edit/:patientId', component: PatientEditComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'patient-detail/:patientId', component: PatientDetailComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'rx-list', component: RxListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'rx-detail/:rxId', component: PatientDetailComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'rx-detail/:rxId', component: PatientDetailComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'supply-order', component: SupplyOrderComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'order-list', component: OrderListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'order-detail/:orderId/:orderNumber/:orderInjectionClinicDoctor/:orderInjectionClinicDate/:reconciled', component: OrderDetailComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'admin/user-list', component: UserListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'admin/doctor-list', component: DoctorListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'admin/clinic-list', component: ClinicListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'admin/supply-list', component: SupplyListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'reports/clinic-schedule', component: ClinicScheduleComponent},
      { path: 'admin/utility', component: UtilityComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'reports/fax-report', component: FaxReportComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'reports/rx-report', component: RxReportComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'mobile', component: PatientMobileComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
      { path: 'injection-clinic/injection-clinic-list', component: InjectionClinicListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
    ]
  },
  { path: '', pathMatch: 'full', redirectTo: '/login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
