<mat-toolbar color="accent">
    <span>Rx Report</span>
    <span class="fill-space"></span>
    <!-- <button mat-flat-button (click)="AddPatient()">{{ 'patients.newpatient' | translate }}</button> -->
</mat-toolbar>
<kendo-grid
    [kendoGridBinding]="rx"
    kendoGridSelectBy="id"
    [pageSize]="15"
    [pageable]="true"
    [sortable]="{
        allowUnsort: true,
        mode: 'single'
    }"
    [sort]="sort"
    [filterable]="true"
    [groupable]="false"
    [reorderable]="true"
    [resizable]="true"
    [columnMenu]="{ filter: true }"
    [loading]="reportService.loading"
>
<kendo-grid-messages filterBooleanAll="{{ 'report.filter.all' | translate }}" filterIsFalse="{{ 'report.filter.unfilled' | translate }}" filterIsTrue="{{ 'report.filter.filled' | translate }}" noRecords="{{ 'global.norecords' | translate }}" pagerOf=":" pagerItems="{{ 'global.items' | translate }}">
</kendo-grid-messages>
<!-- <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column> -->
<kendo-grid-column field="order_number" title="{{ 'report.ordernumber' | translate }}" [width]="150"></kendo-grid-column>
<!-- <kendo-grid-column field="location.name" title="{{ 'report.location' | translate }}" [width]="150"></kendo-grid-column> -->
<kendo-grid-column field="patient.full_name" title="{{ 'report.patientname' | translate }}" [width]="180"></kendo-grid-column>
<kendo-grid-column field="doctor.full_name" title="{{ 'report.physician' | translate }}" [width]="180"></kendo-grid-column>
<!-- <ng-container *ngIf="user">
    <kendo-grid-column *ngIf="user.clinic.requiresAppointmentTime" [filterable]="false" field="injectiondate" [format]="{ date: 'short' }" [sortable]="true"  title="{{ 'rx.time' | translate }}" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.injectiondate">{{ dataItem?.injectiondate | date: 'hh:mm a' }}</span>
      </ng-template>
    </kendo-grid-column>
  </ng-container> -->
  <kendo-grid-column field="medication_name" title="{{ 'rx.medication' | translate }}"  [width]="150"></kendo-grid-column>
  <kendo-grid-column field="eye" title="{{ 'rx.eye' | translate }}" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span translate>{{ dataItem.eye  }}</span>
    </ng-template>
  </kendo-grid-column>
<kendo-grid-column field="quantity_numeric" filter="numeric" title="{{ 'report.quantity' | translate }}" [width]="150"></kendo-grid-column>
<kendo-grid-column field="createAt" filter="date" [filterable]="false" title="{{ 'report.datereceived' | translate }}" [width]="220">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.createdAt">{{dataItem.createdAt | date: 'yyyy-MM-dd h:mm:ss a' }}</span>
    </ng-template> 
</kendo-grid-column>
<!-- <kendo-grid-column field="filled" filter="boolean" title="{{ 'report.filled' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span translate>{{ dataItem.filled }}</span>
    </ng-template> 
</kendo-grid-column> -->
<kendo-grid-column field="status" title="{{ 'orders.status' | translate }}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span translate>{{ dataItem.status }}</span>
    </ng-template>
  </kendo-grid-column>
<kendo-grid-column [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.documentPdf">
            <a *ngIf="dataItem.documentPdf.url" mat-button color="accent" [href]="dataItem.documentPdf.url" target="_blank">Rx</a>
        </ng-container>
        <button *ngIf="dataItem.note" mat-button color="accent" (click)="viewNote(dataItem.note)">Note</button>
    </ng-template>
  </kendo-grid-column>
  <ng-container *ngIf="user">
    <kendo-grid-column *ngIf="user.roles.retine_directe_admin"  [includeInChooser]="false" [sortable]="false" [filterable]="false" [width]="125">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button mat-button color="accent" (click)="fixPDF(dataItem)">Fix</button>
        </ng-template>
      </kendo-grid-column>
  </ng-container>
</kendo-grid>

<ng-template #noteDialog>
    <mat-dialog-content>
        {{ selectedNote }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="ok">{{ 'global.ok' | translate }}</button>
    </mat-dialog-actions>
</ng-template>
