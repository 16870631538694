
<div class="bounds">
    <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
      <div fxFlex="100" fxLayoutAlign="center center" class="sec1">
        <!-- <div>
          <img src="../../assets/images/logo.png" style="min-width: 200px; max-width: 80%;">
        </div> -->
        <mat-card>
          <img src="../../assets/images/logo.png" style="min-width: 200px; max-width: 80%;">
          <!-- <mat-card-header>
            <mat-card-title class="large_text">
  
            </mat-card-title>
          </mat-card-header> -->
          <div style="padding: 10px;">
            <h1>
              {{ 'login.welcome' | translate }}, Dr {{ doctorName }}
            </h1>
          </div>
          <ng-container *ngIf="!complete">
          <div>
            <form [formGroup]="doctorForm">
                    <div>       
                       <mat-form-field class="fc100"  appearance="outline">
                            <mat-label>{{ 'doctors.licensenumber' | translate }}</mat-label>
                            <input matInput [formControl]="licenseNumberControl" [errorStateMatcher]="matcher" required/>
                            <!-- <mat-icon matSuffix>phone</mat-icon> -->
                            <mat-error *ngIf="licenseNumberControl.hasError('required')"
                            [innerHTML]="'global.required' | translate"></mat-error>
                        </mat-form-field>
                    </div>
                    <div style="border-radius: 5px; border-left: 1px solid #cccccc; border-top: 1px solid #cccccc; border-right: 1px solid #cccccc; border-bottom: 2px dashed black;">
                      <signature-pad [id]="'d0'" [options]="{'minWidth': 2, 'canvasWidth': 500,'canvasHeight': 200 }"
                          (onBeginEvent)="drawStart(0)" (onEndEvent)="drawComplete(0)"></signature-pad>
                  </div>
            </form>
         </div>
         <div style="margin-top: 10px;" align="start">
            {{ 'doctorsignature.physiciansignature' | translate }}
         </div>
         <div style="margin-top: 10px;" align="end">
            <button mat-button color="warn" [disabled]="!signature" (click)="clearSignature(0);">{{ 'rx.clearsignature' | translate }} </button>
            <button mat-flat-button color="accent" [disabled]="!doctorForm.valid || !(licenseNumberControl.value === licenseNumber) || !signature" (click)="saveDoctor()">{{ 'global.save' | translate }}</button>
         </div>
         <div style="color: red !important;" *ngIf="licenseNumberControl.dirty && (licenseNumberControl.value !== licenseNumber)" style="margin-top: 10px;" align="start">
            {{ 'doctorsignature.license_error' | translate }}
         </div>
        </ng-container>
         <div *ngIf="message">
            {{ message }}
         </div>
        </mat-card>

    </div>
  </div>  