import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { merge, Subject } from 'rxjs';
import { Doctor } from '../shared/models/doctor';
import { Rx } from '../shared/models/rx';
import { InjectionClinic } from '../shared/models/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  ngUnsubscribe: Subject<void> = new Subject();
  public loading: boolean = false;

  constructor(
    private auth: AuthService,
    private db: AngularFirestore
  ) { }

  getClinics() {
    return this.db.collection(`clinics/`, (ref) =>
    ref
      .orderBy('clinic_name', 'asc')
    ).valueChanges({ idField: 'clinic_id' });
  }

  getClinic(clinicId: string) {
    return this.db.collection(`clinics/`).doc(clinicId).valueChanges();
  }

  async updateUserClinic(clinic: any, clinicId: string, clinicName: string)  {
    const user = await this.auth.getCurrentUser();
    const userId = user.id;
    return this.db.collection('users/').doc(userId).set({clinic: clinic, clinic_id: clinicId, clinic_name: clinicName}, {merge: true})
  }

  async updateUserInjectionClinic(injectionclinic?: InjectionClinic)  {
    const user = await this.auth.getCurrentUser();
    const userId = user.id;
    return this.db.collection('users/').doc(userId).set({injectionclinic: injectionclinic}, {merge: true})
  }

  getMedications(province: string) {
    return this.db.collection(`settings/`).doc(`master`).valueChanges();
  }

  dispose() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**** ADMIN *****/
  getAppSettings() {
    return this.db.collection(`settings/`).doc(`master`).valueChanges();
  }

  getAppVersion() {
    return this.db.collection<string[]>(`settings/`).doc(`version`).valueChanges();
  }

  updateAppSettings(data: string) {
    return this.db.collection(`settings/`).doc(`master`).set(data, {merge: false})
  }

  getDocument(path: string) {
    return this.db.doc(path).valueChanges();
  }

  setDocument(path: string, data: string) {
    return this.db.doc(path).set(data, {merge: false})
  }

  updateDocument(path: string, data: any) {
    return this.db.doc(path).set(data, {merge: true})
  }

  getOrdersByOrderId(orderId: string) {
    return this.db.collection<Rx>('prescriptions', (ref) =>
      ref
        .where('order_id', '==', orderId)
    ).valueChanges();
  }

  getInjectionClinic(injectionClinicId: string) {
    return this.db.collection(`injectionclinics/`).doc(injectionClinicId).valueChanges();
  }
}
