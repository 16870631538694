import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { UtilityService } from '../services/utility.service';
import { Clinic } from '../shared/models/clinic';
import { Doctor } from '../shared/models/doctor';
import { Rx } from '../shared/models/rx';
import { User } from '../shared/models/user';
import firebase from "firebase/app";
import { CreatedBy, Order, RxOrder, SupplyOrder } from '../shared/models/order';
import { environment } from 'src/environments/environment';
import { Location } from '../shared/models/location';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  ngUnsubscribe: Subject<void> = new Subject();
  patients: any = null;
  subscription: any;
  public batchCount: number = 0;
  public loading: boolean = false;

  constructor(
    private db: AngularFirestore,
    private utility: UtilityService
  ) {
  }

  // Get Single Clinic
  getClinic(clinicId: string) {
    return this.db.collection<Clinic>(`clinics`).doc(clinicId);
  }

  getRxOrders(clinicId: string) {
    this.loading = true;
    return this.db.collection('prescriptions', (ref) =>
      ref
        .where('clinic_id', '==', clinicId)
        .orderBy('createdAt', 'desc')
    ).valueChanges({idField: 'id'})
    .pipe(
      takeUntil(this.ngUnsubscribe),
      tap(() => this.loading = false)
    );
  }

  getRxOrder(orderId: string) {
    this.loading = true;
    return this.db.collection<Rx>('prescriptions', (ref) =>
      ref
        .where('order_id', '==', orderId)
        .orderBy('createdAt', 'desc')
    ).valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getDoctorsByClinic(clinicId: string)  {
    return this.db.collection<Doctor>(`clinics/${clinicId}/doctors`, ref =>
      ref.orderBy("last_name", "asc")
    ).valueChanges({idField: 'id'});
  }

  addRxToBatch(user: User, rx: Rx)  {
    const rxId = this.db.createId();
    const createdAt = this.utility.timestamp;
    rx.createdAt = createdAt;
    rx.id = rxId;
    rx.language = "fr";
    rx.AppointmentComplete = false;
    rx.software_version = environment.VERSION;
    rx.clinic_id = user.clinic_id;
    rx.clinic_name = user.clinic_name;
    rx.clinic_province = user.clinic_province;
    rx.createdById = user.id;
    rx.createdBy = user.first_name + ' ' + user.last_name;
    rx.doctor_id = rx.doctor.id;
    rx.filled = false;
    rx.status = 'Batch';
    return this.db.doc(`prescriptions/${rxId}`).set(rx)
  }

  getCurrentInjectionClinicBatch(clinicId: string, injectionId: string) {
    this.loading = true;
    return this.db.collection<Rx>('prescriptions', (ref) =>
      ref
        .where('clinic_id', '==', clinicId)
        .where('injectionclinic.id', '==', injectionId)
        .where('status', '==', 'Batch')
        .orderBy('createdAt', 'desc')
    ).valueChanges({idField: 'id'})
    .pipe(
      map((prescriptions) => {
        this.batchCount = prescriptions.length;
        console.log('Prescription Count: ', this.batchCount);
        // orders.forEach(order => {
        //   order['doctor'].full_name = order['doctor'].first_name + ' ' + order['doctor'].last_name;
        // })
        // prescriptions.map(rx => {
        //   delete rx.doctor.signature
        // })
        return prescriptions;
      }),
      takeUntil(this.ngUnsubscribe),
      tap(() => this.loading = false)
    );
  }

  getCurrentBatch(clinicId: string, userId: string) {
    this.loading = true;
    return this.db.collection<Rx>('prescriptions', (ref) =>
      ref
        .where('clinic_id', '==', clinicId)
        .where('createdById', '==', userId)
        .where('status', '==', 'Batch')
        .orderBy('createdAt', 'desc')
    ).valueChanges({idField: 'id'})
    .pipe(
      map((prescriptions) => {
        this.batchCount = prescriptions.length;
        console.log('Prescription Count: ', this.batchCount);
        // orders.forEach(order => {
        //   order['doctor'].full_name = order['doctor'].first_name + ' ' + order['doctor'].last_name;
        // })
        // prescriptions.map(rx => {
        //   delete rx.doctor.signature
        // })
        return prescriptions;
      }),
      takeUntil(this.ngUnsubscribe),
      tap(() => this.loading = false)
    );
  }

  getOrdersByClinic(clinicId: string) {
    this.loading = true;
    return this.db.collection('orders', (ref) =>
      ref
        .where('clinic_id', '==', clinicId)
        .orderBy('createdAt', 'desc')
    ).valueChanges({idField: 'id'})
    .pipe(
      map((orders) => {
        return orders;
      }),
      takeUntil(this.ngUnsubscribe),
      tap(() => this.loading = false)
    );
  }

  deleteRx(rxId: string)  {
    return this.db.doc(`prescriptions/${rxId}`).delete();
  }

  async deleteRxBatchByClinic(clinicId: string)  {
    const batchList: Observable<firebase.firestore.QuerySnapshot> = await this.db.collection('prescriptions', ref => ref.where('clinic_id', '==', clinicId).where('status', '==', 'Batch')).get();
    
    const batch = this.db.firestore.batch();
    batchList.pipe(
      mergeMap(rx => rx.docs),
      map((rx: firebase.firestore.QueryDocumentSnapshot) => batch.delete(rx.ref))
    ).toPromise().then(() => batch.commit());    
  }

  async deleteRxBatchByInjectionClinic(clinicId: string, injectionClinicId: string)  {
    const batchList: Observable<firebase.firestore.QuerySnapshot> = await this.db.collection('prescriptions', ref => ref.where('clinic_id', '==', clinicId).where('injectionclinic.id', '==', injectionClinicId).where('status', '==', 'Batch')).get();
    
    const batch = this.db.firestore.batch();
    batchList.pipe(
      mergeMap(rx => rx.docs),
      map((rx: firebase.firestore.QueryDocumentSnapshot) => batch.delete(rx.ref))
    ).toPromise().then(() => batch.commit());    
  }

  async createRxOrder(order: RxOrder) {
    const orderId = this.db.createId();
    const createdAt = this.utility.timestamp;
    order.id = orderId;
    order.software_version = environment.VERSION;
    order.createdAt = createdAt;
    order.number = await this.getUniqueOrderNumber().catch((err) => { throw err });
    order.order_number = `RD${this.utility.pad(order.number, 10, '0')}`
    order.count = order.rx.length;
    let batch = this.db.firestore.batch();
    order.rx.forEach((rx) => {
      const doctor = order.orderDoctors.find(doc => doc.license_number === rx.doctor.license_number);
      const signature = doctor.signature;
      batch.set(this.db.doc(`prescriptions/${rx.id}`).ref, { status: 'Queued', email: order.email, faxnumber: order.faxnumber, order_id: orderId, number: order.number, order_number: order.order_number, 'doctorSignature': signature } , {merge: true} );
      delete rx.doctor.signature;
    });
    console.log('Set rx batches', order);
    const clinicOrderRef = this.db.doc(`clinics/${order.clinic_id}/orders/${orderId}`).ref;
    batch.set(clinicOrderRef , order);
    console.log('Set order batch');
    const clinicInjectionClinicRef = this.db.doc(`clinics/${order.clinic_id}/injectionclinics/${order.injectionclinic.id}`).ref;
    batch.set(clinicInjectionClinicRef, {submitted: true} , {merge: true} );
    const injectionClinicRef = this.db.doc(`injectionclinics/${order.injectionclinic.id}`).ref;
    batch.set(injectionClinicRef, {submitted: true} , {merge: true} );
    const orderDocRef = this.db.doc(`orders/${orderId}`).ref;
    batch.set(orderDocRef, order);   
    return batch.commit();
  }

  async createSupplyOrder(order: SupplyOrder) {
    const orderId = this.db.createId();
    const createdAt = this.utility.timestamp;
    order.id = orderId;
    order.software_version = environment.VERSION;
    order.createdAt = createdAt;
    order.number = await this.getUniqueOrderNumber().catch((err) => { throw err });
    order.order_number = `RD${this.utility.pad(order.number, 10, '0')}`
    order.count = order.Supplies.length;
    let batch = this.db.firestore.batch();
    const orderDocRef = this.db.doc(`orders/${orderId}`).ref;
    batch.set(orderDocRef, order);
    
    const clinicOrderRef = this.db.doc(`clinics/${order.clinic_id}/orders/${orderId}`).ref;
    batch.set(clinicOrderRef , order);
    return batch.commit();
  }

  async updateRx(clinicId: string, rxId: string, doc: any, merge: boolean) {
    const updatedAt = this.utility.timestamp;
    let batch = this.db.firestore.batch();
    const rxDocRef = this.db.doc(`prescriptions/${rxId}`).ref;
    batch.set(rxDocRef, { updatedAt, ...doc }, { merge: merge} );
  
    const clinicOrderRef = this.db.doc(`clinics/${clinicId}/prescriptions/${rxId}`).ref;
    batch.set(clinicOrderRef, { updatedAt, ...doc }, { merge: merge} );
    return batch.commit();
  }

  async getUniqueOrderNumber(): Promise<number>  {
    return new Promise((resolve, reject) => {
      const increment = firebase.firestore.FieldValue.increment(1);
      const program = this.db.doc(`settings/master`);
      program.update({ orderId: increment }).then(t => {
        program.ref.get().then(function(doc) {
          if (doc.exists) {
            const orderId = doc.data()['orderId'];
            resolve(orderId);
          } else {
            reject(null);
          }
        })
        .catch(err => {
          reject(1);
        })
      })
      .catch(err => {
        reject(2);
      })
    });
  }

  getSupplyByClinic(clinicId: string)  {
    this.loading = true;
    return this.db.collection("supplies", ref => 
      ref
        .where("clinics", "array-contains", clinicId)
        .orderBy("name", "asc")
    ).valueChanges({idField: 'id'})
    .pipe(
      tap(() => this.loading = false)
    );
  }

  getDefaultSuppliesByDoctor(doctorId: string)  {
    // console.log(doctor_id);
    return this.db.collection("supplies", ref => 
      ref
        .where("doctors", "array-contains", doctorId)
        .orderBy("sort", "asc")
    )
    .valueChanges({idField: 'id'});
  }

  async createInjectionClinic(clinic: Clinic, name: string, clinicDate: Date, location: Location) {
    const inectionClinicId = this.db.createId();
    const createdAt = this.utility.timestamp;
    const doc = {id: inectionClinicId, createdAt, clinic_id: clinic.clinic_id, clinic_name: clinic.clinic_name, name: name, clinicDate, location: location, submitted: false};
    let batch = this.db.firestore.batch();
    const injectionClinicDocRef = this.db.doc(`injectionclinics/${inectionClinicId}`).ref;
    batch.set(injectionClinicDocRef, doc);
    
    const injectionClinicClinicDocRef = this.db.doc(`clinics/${clinic.clinic_id}/injectionclinics/${inectionClinicId}`).ref;
    batch.set(injectionClinicClinicDocRef , doc);
    return batch.commit();
  }

  getInjectionClinicsByClinic(clinicId)  {
    this.loading = true;
    return this.db.collection(`clinics/${clinicId}/injectionclinics`, (ref) =>
      ref
        .where('submitted', '==', false)
        .orderBy('createdAt', 'asc')
    ).valueChanges({idField: 'id'})
    .pipe(
      map((orders) => {
        return orders;
      }),
      takeUntil(this.ngUnsubscribe),
      tap(() => this.loading = false)
    ); 
  }

  async reconcileOrder(clinicId: string, orderId: string, user: User) {
    const updatedAt = this.utility.timestamp;
    let batch = this.db.firestore.batch();
    let updateDoc = { updatedAt: updatedAt, updatedById: user.id, updatedBy: user.display_name, reconciled: true, reconciledAt: updatedAt, reconciledById: user.id, reconciledBy: user.display_name};
    const clinicOrderRef = this.db.doc(`clinics/${clinicId}/orders/${orderId}`).ref;
    batch.set(clinicOrderRef , updateDoc, { merge: true});
    const orderDocRef = this.db.doc(`orders/${orderId}`).ref;
    batch.set(orderDocRef, updateDoc, { merge: true});
    return batch.commit();
  }

  getDoctor(clinicId: string, doctorId: string) {
    return this.db.doc<Doctor>(`clinics/${clinicId}/doctors/${doctorId}`).valueChanges({idField: 'id'})
  }

  dispose() {
    console.log("Destroy Order Service")
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}