<div>
    <mat-toolbar color="accent">
        <span>{{ 'supplyorder.title' | translate }}</span>
        <span class="fill-space"></span>
        <ng-container *ngIf="order">
            <button mat-flat-button (click)="openSubmitOrderDialog()">{{
                'supplyorder.submitsupplyorder' | translate }}</button>&nbsp;
            <button mat-flat-button (click)="resetOrder()" color="warn">{{ 'supplyorder.reset' |
                translate }}</button>
        </ng-container>
    </mat-toolbar>
    <kendo-grid [kendoGridBinding]="order" kendoGridSelectBy="id" [pageable]="false" [sortable]="true"
        [filterable]="true" [groupable]="true" [group]="groups" (groupChange)="groupChange($event)" [reorderable]="true"
        [resizable]="true" [columnMenu]="{ filter: true }" [loading]="loading">
        <kendo-grid-messages noRecords="{{ 'global.norecords' | translate }}" pagerOf=":"
            pagerItems="{{ 'global.items' | translate }}">
        </kendo-grid-messages>
        <!-- <kendo-grid-column field="din" title="{{ 'supplyorder.din' | translate }}"></kendo-grid-column> -->
        <kendo-grid-column field="name" title="{{ 'supplyorder.name' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="category" title="{{ 'supplyorder.category' | translate }}" [width]="250"></kendo-grid-column>
        <kendo-grid-column field="size" title="{{ 'supplyorder.size' | translate }}" [width]="175"></kendo-grid-column>
        <kendo-grid-column field="strength" title="{{ 'supplyorder.strength' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="unit" title="{{ 'supplyorder.unit' | translate }}" [width]="175"></kendo-grid-column>
        <kendo-grid-column field="price" title="{{ 'supplyorder.price' | translate }}" [width]="175"></kendo-grid-column>
        <kendo-grid-column field="quantity" title="{{ 'supplyorder.quantity' | translate }}" [width]="155">
            <ng-template kendoGridCellTemplate let-dataItem>
                <mat-form-field class="fc100">
                    <input placeholder="{{ 'supplyorder.quantity' | translate }}" matInput type="number"
                        [(ngModel)]="dataItem.quantity">
                    <button mat-button *ngIf="dataItem.quantity >= 0" matSuffix mat-icon-button aria-label="Clear"
                        (click)="dataItem.quantity=null">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>


<ng-template #callDeleteConfirmDialog>
    <h2 matDialogTitle>Delete?</h2>
    <mat-dialog-content>
        Are you sure you want to do this?
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
        <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #orderSubmitConfirmDialog>
    <h2 matDialogTitle class="title">{{ 'rx.dialog.sendorder.title' | translate }}</h2>
    <mat-dialog-content>
        {{ 'rx.dialog.sendorder.message' | translate }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="no">{{ 'global.no' | translate }}</button>
        <button mat-button matDialogClose="yes">{{ 'global.yes' | translate }}</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #orderNothingEnteredConfirmDialog>
    <h2 matDialogTitle>{{ 'supplyorder.dialog.nothingentered.title' | translate }}</h2>
    <mat-dialog-content>
        {{ 'supplyorder.dialog.nothingentered.message' | translate }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose="ok">{{ 'global.ok' | translate }}</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #submitDialog>
    <button mat-icon-button color="light" class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 matDialogTitle class="title">{{ 'rx.sendorder' | translate }} - <span *ngIf="user" class="clinic">{{ user.clinic_name }}</span></h2>
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="orderForm">
            <!-- <img class="logo" [src]="logo"> -->
            <div>
                <mat-form-field class="fc300">
                    <mat-label>{{ 'rx.pharmacy' | translate }}</mat-label>
                    <mat-select [formControl]="faxControl" required [(value)]="this.appSettings.pharmacies[0].faxnumber">
                      <mat-option [value]="appSettings.pharmacies[0].faxnumber">{{ appSettings.pharmacies[0].name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="faxControl.hasError('required')"
                      [innerHTML]="'rx.errors.faxnumberrequired' | translate"></mat-error>
                  </mat-form-field>
                  &nbsp;
                    <mat-form-field class="fc200">
                        <mat-label>{{ 'rx.faxnumberother' | translate }}</mat-label>
                        <input matInput [formControl]="faxnumberOtherControl" [errorStateMatcher]="matcher" />
                        <mat-error *ngIf="faxnumberOtherControl.hasError('pattern')"
                          [innerHTML]="'rx.errors.faxnumberotherpattern' | translate"></mat-error>
                      </mat-form-field>
                </div>
            <div>
                <mat-form-field class="fc150">
                    <mat-label>{{ 'rx.doctor' | translate }}</mat-label>
                    <mat-select [formControl]="doctorControl" (selectionChange)="showSignature($event.value)">
                        <mat-option *ngFor="let doctor of doctors" [value]="doctor">Dr {{ doctor.full_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                &nbsp;
                <mat-form-field class="fc150"> 
                    <mat-label>{{ 'rx.location' | translate }}</mat-label>
                    <mat-select [formControl]="locationControl">
                        <mat-option *ngFor="let location of locations" [value]="location">{{ location.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                &nbsp;                
                <mat-form-field class="fc175">
                    <input matInput [formControl]="expectedDeliveryDateControl" [matDatepicker]="edd" [errorStateMatcher]="matcher" placeholder="{{ 'rx.expecteddeliverydate' | translate}}">
                    <mat-datepicker-toggle matSuffix [for]="edd"></mat-datepicker-toggle>
                    <mat-datepicker #edd disabled="false"></mat-datepicker>
                    <mat-error *ngIf="expectedDeliveryDateControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                  </mat-form-field>
                  &nbsp;    
                  <ng-container *ngIf="user?.roles.retine_directe_admin">            
                  <mat-form-field class="fc175">
                      <input matInput [formControl]="backorderDateControl" [matDatepicker]="bdd" [errorStateMatcher]="matcher" placeholder="{{ 'rx.backorderdate' | translate}}">
                      <mat-datepicker-toggle matSuffix [for]="bdd"></mat-datepicker-toggle>
                      <mat-datepicker #bdd disabled="false"></mat-datepicker>
                      <mat-error *ngIf="backorderDateControl.hasError('required')" [innerHTML]="'global.required' | translate"></mat-error>
                    </mat-form-field>
                  </ng-container>
            </div>
            <div style="border-bottom: 2px dashed black;">
                <signature-pad [id]="'d0'" [options]="{'minWidth': 2, 'canvasWidth': 550,'canvasHeight': 200 }"
                    (onBeginEvent)="drawStart(0)" (onEndEvent)="drawComplete(0)"></signature-pad>
            </div>
            <div>
                <mat-form-field class="note">
                    <mat-label>{{ 'rx.notes' | translate }}</mat-label>
                    <textarea matInput class="note_field" [formControl]="notesControl"></textarea>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="warn" [disabled]="!signature" (click)="clearSignature(0);">{{ 'rx.clearsignature' | translate }} </button>
        <button mat-button mat-dialog-close (click)="orderForm.reset()">{{ 'batch.cancel' | translate }}</button>
        <button mat-button color="accent" [disabled]="!orderForm.valid || !signature" (click)="submitOrder()">{{ 'rx.send' | translate
            }}</button>
    </mat-dialog-actions>
</ng-template>