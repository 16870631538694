import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { first, switchMap, take } from 'rxjs/operators';
import firebase from "firebase/app";
import { User } from '../shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<any>;

  constructor(
    private auth: AngularFireAuth,
    private db: AngularFirestore
  ) { 
    this.user = this.auth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.doc<any>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  async emailLogin(email: string, password: string) {
    try {
      await this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(async () => {
        await this.auth.signInWithEmailAndPassword(email, password);
      });
    } catch (err) {
      throw new Error(err);
    }
  }

  async sendPasswordResetEmail(email: string) {
    try {
      return await this.auth.sendPasswordResetEmail(email)
    } catch (err) {
      throw new Error(err);
    }
  }

  async updatePassword(newPassword: string) {
    let user = await this.auth.currentUser;
    return await user.updatePassword(newPassword).then(async () => {
      return await this.db.doc(`users/${user.uid}`).set({resetPassword: false}, {merge: true})
    })
  }

  async resetPassword(emailAddress: string) {
    this.auth.sendPasswordResetEmail(emailAddress);
  }

  async updateCustomUserData(data: any) {
    console.log('update user data: ', data)
    const user = await this.auth.currentUser;
    const userId = user.uid;
    if (userId) {
      console.log('Update user data: ', userId)
      const userRef: AngularFirestoreDocument<any> = this.db.doc(
        `users/${userId}`
      );
      return userRef.set(data, { merge: true });
    } else {
      console.log('No user')
      return of(null);
    }
    // return this.auth.user.pipe(
    //   switchMap(user => {
    //     if (user) {
    //       console.log('Update user data: ', user)
    //       const userRef: AngularFirestoreDocument<any> = this.db.doc(
    //         `users/${user.uid}`
    //       );
    //       return userRef.set(data, { merge: true });
    //     } else {
    //       console.log('No user')
    //       return of(null);
    //     }
    //   })
    // );
  }

  async logout(): Promise<any> {
    return this.auth.signOut();
  }

  async getCurrentUser(): Promise<User> {
    return this.user.pipe(first()).toPromise();
  }

  async isLoggedIn(): Promise<boolean> {
    const user = await this.getCurrentUser();
    return !!user;
  }

  dispose() {
    
  }

}
