import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import jsPDF from 'jspdf';
import { environment } from 'src/environments/environment';
import { Rx } from '../shared/models/rx';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  pdf: any;
  pageHeight: number;
  pageWidth: number;
  pageNumber: number = 0;
  startx: number = 40;
  starty: number = 40;
  x: number = 40;
  y: number = 40;
  supplies: any[];

  clinic: any;

  task: AngularFireUploadTask;
  filepath: any;

  constructor(
    private afStorage: AngularFireStorage
  ) { }

  public createRxPDF(rx: Rx): Promise<string>  {
    return new Promise(async (resolve, reject) => {
      this.pdf = new jsPDF({
            orientation: 'p', 
            unit: 'pt', 
            format: [400, 450]
      });
      this.pageHeight = this.pdf.internal.pageSize.height;
      this.pageWidth = this.pdf.internal.pageSize.width;
      this.pdf.deletePage(1);
      // this.addNewPage(rx)
      
      await this.addRxOrder(rx);
      // await this.addDisclaimer(rx);
      console.log('done pdf');
      // this.pdf.save('test.pdf');
      const file = this.pdf.output("blob")
      const filepath = await this.uploadOrdertoFirebase(rx, file);
      console.log('Finished Everything, here is the file: ', filepath);
      resolve(filepath);
    });
  }

  private async addRxOrder(rx: Rx)  {
    return new Promise<void>(async (resolve, reject) => {
        await this.addNewPage(rx);
        this.pdf.setFontSize(10);
        let patientinfo = rx.patient.first_name + ' ' + rx.patient.last_name + ' - DOB: ' + rx.patient.dob;
        if(rx.patient.gender) {
          patientinfo += ' - ' + rx.patient.gender;
        }
        // this.pdf.setFontType("bold");
        this.pdf.setFontSize(10);
        this.pdf.text(patientinfo, this.x, this.y); this.y += 15;
        // this.pdf.setFontType("normal");
        this.pdf.setFontSize(10);
        this.pdf.text('Health Card #: ' + rx.patient.healthcard, this.x, this.y);  this.y += 15;
        let patientaddress = rx.patient.address + ' ' + rx.patient.city + ', ' + rx.patient.province + ' ' + rx.patient.postal_code;
        if(rx.patient.phone_number) {
          patientaddress += ' - ' + rx.patient.phone_number;
        } else {
          if (rx.patient.phone_number_primary) {
            patientaddress += ' - ' + rx.patient.phone_number_primary;
          }
        } 
        this.pdf.text(patientaddress, this.x, this.y); this.y += 15;
        this.pdf.text(`${rx.medication_name} - ${rx.eye} - ${rx.quantity} ${rx.lucode ? ' - ' + rx.lucode : ''}`, this.x, this.y); this.y += 15;
        if(rx.tobra)  {
          this.pdf.text('Plus: ' + rx.tobra, this.x, this.y); this.y += 15;
        }
        if(rx.note)  {
          let lines = this.pdf.splitTextToSize('RX Note: ' + rx.note, this.pageWidth - 80);
          let textheight = 10 * lines.length * 1.05;
          this.pdf.setFontSize(10);
          // this.pdf.setFontType("normal");
          this.pdf.text(this.x, this.y, lines); this.y += textheight;
        } 
        this.y += 10;
        this.pdf.setFontSize(6);

        let disclaimer = "";
        disclaimer += "Please deliver this medication to my office on behalf on this patient so I can ensure the drug is properly stored and handled prior to administration. I have informed this patient of their right to choose another pharmacy if they prefer.  To best serve our patients, I will educate them prior to administering the drug on, but not limited to, the following:\n\n";
        disclaimer += " - If he/she has ever received an injection of this medication before, and if so, whether he/she has had an allergic reaction to this product\n";
        disclaimer += " - The medications purpose and recommendation for use\n";
        disclaimer += " - Medication contraindications and possible risks associated with injection administration\n";
        disclaimer += " - Drug-Drug interactions if applicable\n\n";
        disclaimer += "As the prescribing specialist, I am very familiar with this medication, including the consumer information contained in the manufacturer’s product monograph and I am very comfortable counselling on both the injection and drug components. In the interest of our patients and to avoid confusion, please do not counsel for the initial prescription, however please be available to the patient should they call for additional information.";

        let para = this.pdf.splitTextToSize(disclaimer, this.pageWidth - 100);
        let textheight = 10 * para.length * 1.05;
        this.pdf.text(this.x, this.y, para); this.y += 20 + textheight;
        console.log('completed rx')
        resolve();
    });
  }

  private async addNewPage(rx) {
    return new Promise<void>(async (resolve, reject) => {
      this.pdf.addPage();
      this.pageNumber += 1;
      this.y = this.starty;
      await this.addHeader(rx);
      await this.addFooter(rx);
      console.log('add new page complete');
      resolve();
    });
  }

  private async addHeader(rx) {
    return new Promise<void>((resolve, reject) => {
      this.y = 20; 
      this.x = 20;

      // Add Sentrex Logo
      this.pdf.addImage(environment.SENTREX_LOGO, 'png', this.x, this.y, 100, 17, undefined, 'FAST');
      this.y += 30; 

      // Add Clinic Info
      // Clinic Name - Bold
      this.pdf.setFont("helvetica"); 
      // this.pdf.setFontType("bold");
      this.pdf.setFontSize(12);
      this.pdf.text(rx.clinic_name + " - "+ rx.order_number_clinic, this.x, this.y); this.y += 15;
      // this.pdf.setFontType("normal"); 
      if (rx.location)  {
        this.pdf.text(rx.location.name + ' - ' + rx.location.address + ' - ' + rx.location.city, this.x, this.y);  this.y += 15;
      }
      // Clinic Address & Phone Number - Normal
      // this.pdf.text(clinic.address.address, this.x, this.y); this.y += 15;
      // this.pdf.text(clinic.address.city + ', ' + clinic.address.province + ' ' + clinic.address.postal_code, this.x, this.y); this.y += 15;
      // if(clinic.phone_number)  {
      //   this.pdf.text('Tel: ' + clinic.phone_number, this.x, this.y); this.y += 15;
      // }
      // Add Doctor 
      // this.pdf.setFontType("bold");
      this.pdf.text(`Dr. ${rx.doctor.first_name} ${rx.doctor.last_name}`, this.x, this.y);  this.y += 30;
      // this.pdf.setFontType("normal");
      console.log('header complete');
      resolve();
    });
  }

  private addFooter(rx)  {
    return new Promise<void>((resolve, reject) => {
      // Append Dr. Signature Per Page
      this.pdf.addImage(rx.doctor.signature, 'png', this.x, this.pageHeight - 110, 120, 37.5, undefined, 'FAST');
      this.pdf.line(this.x, this.pageHeight - 70, this.x + 150, this.pageHeight - 70);
      this.pdf.setFontSize(10);
      this.pdf.text(`Dr. ${rx.doctor.first_name} ${rx.doctor.last_name} - Prescriber Signature`, this.x, this.pageHeight - 60);
      this.pdf.text((new Date().toLocaleString()), this.x, this.pageHeight - 50);

      // Footer with Clinic and Page Number
      this.pdf.setFontSize(12);
      this.pdf.line(this.x, this.pageHeight - 40, this.pageWidth - 40, this.pageHeight - 40);
      // let nextline = rx.clinic_name;
      // if(batch.order_batch) { nextline += ' - ' + batch.order_batch; }
      // this.pdf.text(nextline, this.x, this.pageHeight - 25);
      // this.pdf.text(this.pageNumber.toString(), this.pageWidth - 60, this.pageHeight - 25);
      this.pdf.setFontSize(7);
      this.pdf.text('Pharmacy: No substitutions permitted. Counseling on medication completed during office visit.\nNo additional information required. ', this.x, this.pageHeight - 25);
      this.pdf.setFontSize(12);
      console.log('footer complete');
      resolve();
    });
  }

  private async uploadOrdertoFirebase(rx: Rx, file: any): Promise<string> {
    console.log("Rx Time: ", rx.createdAt.seconds);
    return new Promise((resolve, reject) => {
      let filePath: any = null;
      filePath = `prescriptions/${rx.clinic_name}_${rx.patient.last_name}_${rx.createdAt.seconds}.pdf`;
  
      console.log("File Path: ", filePath);
      this.task = this.afStorage.ref(filePath).put(file);
    
      this.task.then((t) => {
        t.ref.getDownloadURL().then((u) => {
          console.log("File Upload Complete: ", file); 
          resolve(u);
        })
      }).catch(error => {
        console.log('Upload Error');
        reject(null);
      });
    });
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }
}

