import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';
import { OrderService } from '../../order.service';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import { DateTime } from 'luxon';
import { Clinic } from 'src/app/shared/models/clinic';
import { first, take } from 'rxjs/operators';
import { Location } from 'src/app/shared/models/location';
import { Rx } from 'src/app/shared/models/rx';
import { Medication } from 'src/app/shared/models/medication';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from 'src/app/services/utility.service';
import { Patient } from 'src/app/shared/models/patient';

@Component({
  selector: 'app-rx-add',
  templateUrl: './rx-add.component.html',
  styleUrls: ['./rx-add.component.scss']
})
export class RxAddComponent implements OnInit {
  ngUnsubscribe: Subject<void> = new Subject();

  logo: string;
  patient: Patient;
  doctors: any;
  user: User;
  language: string;
  clinic: Clinic;
  doctor: Doctor = <Doctor>{};
  locations: Location[] = [];
  appSettings: any;
  datestamp: any;
  medications: any[] = [];
  lucodes: string[] = [];
  eyes: any[] = [];
  isEdit: boolean = false;
  rxId: string;
  existingDoctor: any;

  rxForm: FormGroup;
  doctorControl = new FormControl(null, [
    Validators.required,
  ]);
  locationControl = new FormControl(null, [
    Validators.required,
  ]);
  medicationControl = new FormControl(null, [
    Validators.required,
  ]);
  eyeControl = new FormControl(null, [
    Validators.required,
  ]);
  expectedDeliveryDateControl = new FormControl();
  appointmentDateControl = new FormControl();
  lucodeControl = new FormControl();
  tobraControl = new FormControl();
  notesControl = new FormControl();

  constructor( 
    private auth: AuthService,
    private translate: TranslateService,
    private db: DataService,
    private orderService: OrderService,
    private utility: UtilityService,
    public dialogRef: MatDialogRef<RxAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  async ngOnInit() {
    this.language = this.translate.currentLang;
    this.logo = environment.SENTREX_LOGO;
    this.patient = this.data;
    this.datestamp = DateTime.now().toLocaleString(DateTime.DATETIME_FULL);

    this.rxForm = new FormGroup({
      doctor: this.doctorControl,
      location: this.locationControl,
      medication: this.medicationControl,
      eye: this.eyeControl,
      lucode: this.lucodeControl,
      tobra: this.tobraControl,
      expectedDeliveryDate: this.expectedDeliveryDateControl,
      appointmentDate: this.appointmentDateControl,
      notes: this.notesControl
    });
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.appSettings = await this.db.getAppSettings().pipe(first()).toPromise();
    this.appSettings['medications'].forEach(medication => {
        if (medication.provinces.includes(this.user.clinic_province)) {
          this.medications.push(medication);
        }
    });
    this.eyes = this.appSettings['eyes'];
    
    this.doctors = await this.orderService.getDoctorsByClinic(this.user.clinic_id).pipe(first()).toPromise();
    if (this.doctors.length === 1) {
      this.doctorControl.setValue(this.doctors[0]);
    }
    this.clinic = await this.orderService.getClinic(this.user.clinic_id).valueChanges({idField: 'id'}).pipe(first()).toPromise();
    let primaryLocation = <Location>{};
    primaryLocation.name = this.clinic.clinic_name;
    primaryLocation.address = this.clinic.address.address;
    primaryLocation.city = this.clinic.address.city;
    primaryLocation.phone_number = this.clinic.phone_number;
    this.locations.push(primaryLocation);
    this.locations = this.locations.concat(this.clinic.locations);
    console.log("Locations: ", this.locations);
    this.locationControl.setValue(this.locations[0]);

    // this.orderService.getDoctorsByClinic(this.user.clinic_id).subscribe(doctors => {
    //   this.doctors = doctors;
    //   if (this.doctors.length === 1) {
    //     this.doctorControl.setValue(this.doctors[0]);
    //   }
    // });
    // this.orderService.getClinic(this.user.clinic_id).valueChanges({idField: 'id'}).pipe(take(1)).subscribe(clinic => {
    //   console.log("Clinic Info: ", clinic);
    //   this.clinic = clinic;
    //   let primaryLocation = <Location>{};
    //   primaryLocation.name = this.clinic.clinic_name;
    //   primaryLocation.address = this.clinic.address.address;
    //   primaryLocation.city = this.clinic.address.city;
    //   primaryLocation.phone_number = this.clinic.phone_number;
    //   this.locations.push(primaryLocation);
    //   this.locations = this.locations.concat(this.clinic.locations);
    //   console.log("Locations: ", this.locations);
    //   this.locationControl.setValue(this.locations[0]);
    // });
    // this.db.getAppSettings().subscribe(settings => {
    //   this.appSettings = settings;
    //   settings['medications'].forEach(medication => {
    //     if (medication.provinces.includes(this.user.clinic_province)) {
    //       this.medications.push(medication);
    //     }
    //   });
    //   this.eyes = settings['eyes'];
    //   console.log(this.eyes)
    // })

    if (!this.data.first_name) {
      const existingRx = {...this.data};
      this.isEdit = true;
      this.rxId = existingRx.id;
      this.rxForm.patchValue(existingRx);
      // this.doctorControl.value(existingRx.doctor)
      this.patient = existingRx.patient;
      this.existingDoctor = existingRx.doctor;
      this.doctorControl.setValue(this.existingDoctor.id);
      this.locationControl.setValue(existingRx.location)
      // this.dobControl.setValue(dobNew);
      // this.pageTitle = existingPatient.full_name;
      // if (existingPatient.insurance_provider === 'RAMQ') this.insuranceGroupControl.disable();
    } else {
      this.isEdit = false;
      this.patient = {...this.data}
    }
  }

  addRx() {
    // console.log('Form: ', this.rxForm.value)
    let rx: Rx = this.rxForm.value;
    rx.clinic = this.user.clinic;
    rx.patient = this.patient;
    rx.medication_name = rx.medication['name'];
    rx.patient_id = this.patient.id;
    if (rx.eye === 'BOTH') {
      rx.quantity = "2x";
      rx.quantity_numeric = 2;
    } else {
      rx.quantity = "1x";
      rx.quantity_numeric = 1;
    }
    this.orderService.addRxToBatch(this.user, rx).then(() => {
      this.utility.showSnackBar(this.translate.instant('rx.success.add'), 2000, 'center', 'top');
      this.close();
    })
    .catch((err) => {
      this.utility.showSnackBar(this.translate.instant('rx.error.add'), 2000, 'center', 'top');
    })
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.orgId === c2.orgId : c1 === c2; 
 }

  close() {
    this.dialogRef.close();
  }

}
