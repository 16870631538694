import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { pdf } from '@progress/kendo-drawing';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UtilityService } from 'src/app/services/utility.service';
import { User } from 'src/app/shared/models/user';
import { ReportService } from '../report.service';
import { DateTime } from 'luxon';
import * as moment from "moment";

@Component({
  selector: 'app-rx-report',
  templateUrl: './rx-report.component.html',
  styleUrls: ['./rx-report.component.scss']
})
export class RxReportComponent implements OnInit {
  @ViewChild('noteDialog') noteDialog: TemplateRef<any>;
  ngUnsubscribe: Subject<void> = new Subject();
  rx: any;
  public gridView: any;
  user: User;
  appSettings: any;
  selectedNote: string;

  public sort: SortDescriptor[] = [
    {
      field: 'order_number',
      dir: 'desc',
    }
  ];
  
  constructor(
    private auth: AuthService,
    public reportService: ReportService,
    private utility: UtilityService,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private db: DataService
  ) {}

  ngOnInit() {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.reportService.getRx(this.user.clinic_id)
    .pipe(
      takeUntil(this.ngUnsubscribe))
    .subscribe((prescriptions) => {
      prescriptions.map(r => {
        if (r.createdAt) {
          r.createdAt = DateTime.fromSeconds(r.createdAt.seconds).toJSDate();
        }
        if (r.appointmentTime)  {
          // const injectionDateTimeString = `${DateTime.fromSeconds(r.injectiondate.seconds).toFormat('yyyy-MM-dd')} ${r.appointmentTime.replace(' ', ':00').toLowerCase()}`;
          // const result = DateTime.fromSQL(injectionDateTimeString)
          var hour = moment(r.appointmentTime, ["h:mm A"]).format("HH");
          var minute = moment(r.appointmentTime, ["h:mm A"]).format("mm");
          const d = DateTime.fromJSDate(r.injectiondate.toDate()).set({
            hour: parseInt(hour),
            minute: parseInt(minute)
          })
          console.log(d)
          r.injectiondate = d.toJSDate();
        }
      })
      this.rx = prescriptions;
    });
    // .pipe(
    //   takeUntil(this.ngUnsubscribe))
    // .subscribe((prescriptions) => {
    //   prescriptions.map(r => {    
    // .pipe(
    //   map((rxs) => {
    //     rxs.forEach((rx) => {
    //       if (rx.createdAt) {
    //         rx.createdAt = DateTime.fromSeconds(rx.createdAt.seconds).toJSDate();
    //       }
          
    //       if (rx.appointmentTime)  {
    //         // const injectionDateTimeString = `${DateTime.fromSeconds(r.injectiondate.seconds).toFormat('yyyy-MM-dd')} ${r.appointmentTime.replace(' ', ':00').toLowerCase()}`;
    //         // const result = DateTime.fromSQL(injectionDateTimeString)
    //         var hour = moment(rx.appointmentTime, ["h:mm A"]).format("HH");
    //         var minute = moment(rx.appointmentTime, ["h:mm A"]).format("mm");
    //         const d = DateTime.fromJSDate(rx.injectiondate.toDate()).set({
    //           hour: parseInt(hour),
    //           minute: parseInt(minute)
    //         })
    //         rx.injectiondate = d.toJSDate();
    //       }
    //     });
    //     return rxs;
    //   }),
    //   takeUntil(this.ngUnsubscribe)
    // );

    this.db.getAppSettings().subscribe(settings => {
      this.appSettings = settings;
    });
  }

  viewNote(note)  {
    this.selectedNote = note;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = 300;
    // dialogConfig.disableClose = true;
    let dialogRef = this.matDialog.open(this.noteDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {});
  }

  fixPDF(rx)  {
    let sig = '';
    this.reportService.getDoctor(this.user.clinic_id, rx.doctor.id).subscribe(doc => {
      console.log('Doc', doc);
      sig = doc['signature'];
      console.log(rx.doctor.id, sig);
      this.db.updateDocument(`prescriptions/${rx.id}`, { status: 'pdf', doctorSignature: sig, documentPdf: null}).then(() => {
        this.utility.showSnackBar('fixed prescription node doc', 3000, 'center', 'top');
        this.db.updateDocument(`clinics/${this.user.clinic_id}/patients/${rx.patient.id}/prescriptions/${rx.id}`, { status: 'pdf', doctorSignature: sig, documentPdf: null}).then(() => {
          this.utility.showSnackBar('fixed patient prescription node doc', 3000, 'center', 'top');
        })
        .catch(err => {
          this.utility.showSnackBar('error fixin gpatient prescription node doc', 3000, 'center', 'top');
        })
      })
      .catch(err => {
        this.utility.showSnackBar('error fixing prescription node doc', 3000, 'center', 'top');
      })
    })
  }

  ngOnDestroy() {
    console.log('Destroy Patient View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
