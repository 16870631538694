import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataBindingDirective, GridDataResult } from '@progress/kendo-angular-grid';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { SignaturePad } from 'angular2-signaturepad';
import { Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UtilityService } from 'src/app/services/utility.service';
import { Doctor } from 'src/app/shared/models/doctor';
import { Roles, User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../admin-service.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss']
})
export class DoctorListComponent implements OnInit, OnDestroy {
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild('deleteConfirmDialog') deleteConfirmDialog: TemplateRef<any>;
  @ViewChild('signatureConfirmDialog') signatureConfirmDialog: TemplateRef<any>;
  @ViewChild('addDialog') addDialog: TemplateRef<any>;
  @ViewChild('doctorSuppliesDialog') doctorSuppliesDialog: TemplateRef<any>;

  @ViewChildren(SignaturePad) signaturePads:QueryList<SignaturePad>;
  
  ngUnsubscribe: Subject<void> = new Subject();
  public doctors: any;
  language: string;
  public gridView: any;
  user: User = <User>{};
  roles: Roles = <Roles>{};
  loading: boolean = false;
  isSavingUser: boolean = false;
  isEdit: boolean = false;
  addDialogRef: any;
  public mySelection: string[] = [];
  public sort: SortDescriptor[] = [
    {
        field: 'last_name',
        dir: 'asc'
    },
    {
      field: 'first_name',
      dir: 'asc'
    }
  ];
  private editedRowIndex: number;

  public deleteDoctorFullName: string;
  public typedDoctorFullName: string;
  doctorSupplies: any[] = [];
  selectedDoctor: Doctor;
  lastCategory: string;
  signature: string;

  doctorForm: FormGroup;
  firstNameControl = new FormControl('', [Validators.required,]);
  lastNameControl = new FormControl('', [Validators.required,]);
  licenseNumberControl = new FormControl(null, [Validators.required]);
  languageControl = new FormControl('fr', [Validators.required]);
  emailControl = new FormControl('');

  matcher = new MyErrorStateMatcher();

  constructor(
    private auth: AuthService,
    public adminService: AdminService,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private utility: UtilityService,
    private spinner: SpinnerService,
    private httpClient: HttpClient
    ) { }

  ngOnInit() {
    this.doctorForm = new FormGroup({
      first_name: this.firstNameControl,
      last_name: this.lastNameControl,
      license_number: this.licenseNumberControl,
      language: this.languageControl,
      email: this.emailControl
    })
  }

  async ngAfterViewInit() {
    this.user = await this.auth.getCurrentUser();
    this.language = this.translate.currentLang;
    this.doctors = this.adminService.getDoctors(this.user.clinic_id).pipe(takeUntil(this.ngUnsubscribe));
  }

  ngOnDestroy() {
    console.log('Destroy User View');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  DeleteDoctor(doctor: Doctor) {
    this.deleteDoctorFullName = doctor.full_name;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    let dialogRef = this.matDialog.open(this.deleteConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result === 'yes') {
              if (this.deleteDoctorFullName === this.typedDoctorFullName) {
                this.spinner.spin$.next(true);
                this.adminService.deleteDoctor(this.user.clinic_id, doctor.id).then(() => {
                  this.utility.showSnackBar(this.translate.instant('doctors.delete.success'), 3000, 'center', 'top');
                })
                .catch((err) => {
                  this.utility.showSnackBar(this.translate.instant('doctors.error.delete'), 3000, 'center', 'top');
                })
                .finally(() => {
                  this.deleteDoctorFullName = null;
                  this.typedDoctorFullName = null;
                  this.spinner.spin$.next(false);
                })
              } else {
                this.deleteDoctorFullName = null;
                this.typedDoctorFullName = null;
              }
            } 
        }
    })
  }

  openAddDoctorDialog(doctor?: Doctor) {
    this.firstNameControl.setValue('');
    this.lastNameControl.setValue('');
    this.licenseNumberControl.setValue('');
    this.emailControl.setValue('');
    this.languageControl.setValue('fr');

    if (doctor) { 
      this.isEdit = true;
      this.selectedDoctor = doctor;
      this.doctorForm.patchValue(doctor);
    } else {
      this.isEdit = false;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    this.addDialogRef = this.matDialog.open(this.addDialog, dialogConfig);
    this.addDialogRef.afterOpened().subscribe(result => {
      if (doctor.signature) this.setExistingSignature(0, doctor.signature);
    });
    this.addDialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
            if (result !== 'yes') {
              
            } 
        }
    })
  }

  saveDoctor() {
    this.isSavingUser = true;
    this.spinner.spin$.next(true);

      let newDoctor: Doctor = <Doctor>{};
      const d = this.doctorForm.value;
      if (this.selectedDoctor) newDoctor.id = this.selectedDoctor.id;
      newDoctor.first_name = d.first_name;
      newDoctor.last_name = d.last_name;
      newDoctor.full_name = d.first_name + ' ' + d.last_name;
      newDoctor.license_number = d.license_number;
      newDoctor.email = d.email ? d.email : null;
      newDoctor.language = d.language;
      newDoctor.signature = this.signature ? this.signature : null;
      this.adminService.setDoctor(this.user, newDoctor)
      .then(() => {
        this.utility.showSnackBar(this.translate.instant('doctors.addsuccess'), 2000, 'center', 'top');
        this.isSavingUser = false;
        this.selectedDoctor = null;
        this.isEdit = false;
        this.firstNameControl.setValue('');
        this.lastNameControl.setValue('');
        this.licenseNumberControl.setValue('');
        this.languageControl.setValue('fr');
        this.emailControl.setValue('');
        this.addDialogRef.close();
      })
      .catch((err) => {
        this.utility.showSnackBar(this.translate.instant('doctors.error.add'), 2000, 'center', 'top');
      })
      .finally(() => {
        this.isSavingUser = false;
        this.spinner.spin$.next(false);
      })
  }

  updateDoctorSupplies(doctor: Doctor)  {
    this.selectedDoctor = doctor;
    this.adminService.getClinicSuppliesList(this.user.clinic_id)
    .pipe(
      take(1)
    )
    .subscribe(supplies => {
      this.doctorSupplies = supplies;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.width = '800px';
      let dialogRef = this.matDialog.open(this.doctorSuppliesDialog, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {});
    })
  }

  supplyChange(change: MatSelectionListChange) {
    let supply = change.option.value;
    if (change.option.selected) {
      if(supply.doctors) {
        supply.doctors.push(this.selectedDoctor.id);
      }
      else {
        supply['doctors'] = [];
        supply.doctors.push(this.selectedDoctor.id);
      }
    } else {
      if(supply.doctors)  {
        const index: number = supply.doctors.indexOf(this.selectedDoctor.id, 0);
        if (index > -1) {
          supply.doctors.splice(index, 1);
       }
      }
      if(supply.doctors)  {
        const index: number = supply.doctors.indexOf(this.selectedDoctor.id, 0);
        if (index > -1) {
          supply.doctors.splice(index, 1);
        }
      }
    }
    this.adminService.updateSupply(supply)
    .then(()=>{})
    .catch((err) => {});
  }

  categoryDivider(category: string) {
    if (this.lastCategory !== category) {
      this.lastCategory = category;
      return true;
    } else {
      this.lastCategory = category;
      return false;
    }
  }

  clearSignature(index) {
    let signaturePad = this.signaturePads.toArray()[index];
    signaturePad.clear();
    this.signature = null;
  }

  setExistingSignature(index, signature: string) {
    let signaturePad = this.signaturePads.toArray()[index];
    this.signature = signature;
    signaturePad.fromDataURL(signature);
  }

  drawStart(index) {
    console.log("Started drawing on signature", index);
  }

  drawComplete(index) {
    console.log("Ended drawing on signature", index);
    let signaturePad = this.signaturePads.toArray()[index];
    if(!signaturePad.isEmpty())  {
      this.signature = signaturePad.toDataURL();
    }
  }

  async SendSignatureRequest(doctor: Doctor)  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '450px';
    let dialogRef = this.matDialog.open(this.signatureConfirmDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
        console.log(result);
        if (result !== undefined) {
            if (result === 'yes') {
              
              const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
              let body = ''
              body += 'secret=' + encodeURIComponent(environment.GUID);
              body += '&to=' + encodeURIComponent(doctor.email);
              body += '&language=' + encodeURIComponent(doctor.language);
              body += '&subject=' + encodeURIComponent('Demande de signature du médecin / Physician Signature Request');
              body += '&bodyFrench=' + encodeURIComponent('Votre administrateur de clinique rétine directe a demandé une signature');
              body += '&bodyEnglish=' + encodeURIComponent('Your rétine directe clinic administrator requested a signature');
              body += '&buttonText=' + encodeURIComponent('Ouvrir la page de signature / Open Signature Page');
              body += `&buttonUrl=${environment.BASEURL}doctorsignature/${doctor.language}/${this.user.clinic_id}/${doctor.id}/${doctor.license_number}/${doctor.last_name}`;
              this.httpClient.post<any>(`${environment.FIREBASE_API_URL}/sendEmail`, body, { headers }).pipe(take(1)).subscribe(result => {
                this.utility.showSnackBar(this.translate.instant('doctorsignature.success'), 2000, 'center', 'top');
                console.log(result);
              },
              error => {
                this.utility.showSnackBar(this.translate.instant('doctorsignature.error'), 2000, 'center', 'top');
                console.log(error);
              })
            } 
        }
    })
    // const to = req.body.to;
    // const subject = req.body.subject;
    // const clinicName = req.body.clinicName;
    // const bodyFrench = req.body.frenchText;
    // const bodyEnglish = req.body.bodyEnglish;
    // const buttonText = req.body.buttonText;
    // const buttonUrl = req.body.buttonUrl;


  }
}
